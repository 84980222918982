.base {
    display: flex;
    flex-flow: row nowrap; 
}
  
.label {
    flex: 0 0 342px;
    margin: 4px 0 0; 
}
  
.field {
    flex: 1 1 100%; 
}
  
.tags {
    margin: 0 -8px -20px 0; 
}
  
.tag {
    display: inline-block;
    margin: 0 8px 20px 0;
    vertical-align: top; 
}
  