.Newss_base {
    position: relative;
    width: 302px;
    height: 340px; 
}

.Newss_base a {
    text-decoration: none;
    color: #fff;
    white-space: unset;
}
.Newss_base a:hover {
    text-decoration: none;
    color: #fff;
    white-space: unset;
}

.Newss_inner {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: linear-gradient(180deg, rgba(39, 39, 41, 1), transparent);
    /*background-color: rgba(39, 39, 41, 0.3); /* #050505  rgba 191, 211, 254, 0.2 */
    /*box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);*/
    white-space: normal;
    cursor: pointer; 
}
.Newss_base.Newss_isDisabled .Newss_inner {
    opacity: .3;
    pointer-events: none; 
}

.Newss_base:hover .Newss_inner {
    /*background: linear-gradient(180deg, rgba(39, 39, 41, 1), transparent);*/
    background-color: rgba(39, 39, 41, 0.3); /* #050505  rgba 191, 211, 254, 0.2 */
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);  
    transition: all 0.2s ease-out;
}

.Newss_status {
    position: absolute;
    top: -16px;
    left: -8px;
    z-index: 2; 
}

/*.Newss_header {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: 150px; /* 170px /
    transition: opacity .2s; 
}*/

/*.preview_link*/
.Newss_header {
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
    /*padding: 0 17px 0 0;*/
    /*width: 49%;*/
    border: 1px solid #017cff;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}

.Newss_base:not(.Newss_isDisabled):hover .Newss_header {
    opacity: 1; /*0.4; */
}

.Newss_cover {
    position: relative;
    background: url(/public/static/portal/images/cover.jpg) center no-repeat; 
    display: block;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    padding-top: 56.25%;
    /*transition: transform .3s linear;*/
    transition: transform .3s linear, -webkit-transform .3s linear;
}
.Newss_cover:before {
    content: "";
    transition: background-color .15s ease-out;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
}

.Newss_base:not(.Newss_isDisabled):hover .Newss_cover {
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
}

.Newss_cover__img {
    position: absolute;
    bottom: -1px;
    right: 0;
    -webkit-clip-path: polygon(30px 0, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(30px 0, 100% 0, 100% 100%, 0% 100%);
    background: #017cff;
    padding: 6px 20px 6px 30px;
    color: #e0e3e9;
    font-size: 16px;
    line-height: 1;
    text-transform: uppercase;
    z-index: 3;
}

.Newss_body {
    position: relative;
    z-index: 2;
    flex: 1 1 auto;
    box-sizing: border-box;
    /*padding: 150px 5px 0; /* 170px 20px 0 */
    padding: 5px 5px 0;
    overflow: hidden;
    transition: padding .2s; 
}
/*.Newss_base:not(.Newss_isDisabled):hover .Newss_body:not(.Newss_isDownloadDisabled--1NQaZ) {
    padding-top: 115px; 
}*/

/*.Newss_cover {
    display: block;
    width: 302px;
    height: 150px; /* 170px /
    border-radius: 10px 10px 0 0;
    background: url(/public/static/portal/images/cover.jpg) center no-repeat; 
    outline: 1px solid #017cff; /* #ff5001 /
    outline-offset: -1px;
}*/

.Newss_title {
    margin: 0 0 5px;
    color: rgb(206, 212, 224); /*color: #e9e3be;*/
    font-size: 20px;
    font-weight: 700;
    line-height: 22px; 
}

.Newss_clampLines {
    word-wrap: break-word; 
}

.Newss_summary {
    display: flex;
    flex-flow: row wrap;
    margin: 0 0 16px;
    color: rgb(206, 212, 224); /*color: #97947f;*/
    font-size: 13px;
    line-height: 16px; 
}
.Newss_summary mark {
    background: none;
    color: rgb(171, 179, 255); /*color: #e9e3be; */
}

.Newss-date {
    margin: 0 0px 5px 0;
    color: rgb(206, 212, 224, 0.5);
    font-size: 12px;
}
.Newss-desc {
    margin: 0 0px 0 0;
    color: rgb(206, 212, 224, 1);
    font-size: 14px;
}
.Newss-desc mark {
    background: none;
    color: #000;
    font-weight: 700;
}


.Newss-button {
    /*height: 40%;*/
    position: absolute;
    bottom: 10px;
    transition: opacity 0.2s;
    margin: 0 5px 0 0;
    opacity: 0;
}
.Newss_base:not(.Newss_isDisabled):hover .Newss-button {
    opacity: 1;
    transition: opacity .2s; 
}
.ButtonBlueSmall-news {
    height: 30px;
    width: 60px;
    padding: 0px 20px;
    border: 1px solid;
    border-radius: 16px; /* 25px */
    border-color: rgba(206, 212, 224, 0.2);
    background-color: transparent; /* #0051ff   rgba(206, 212, 224, 1); */
    /* background-color: rgba(191, 211, 254, 1); */
    color: #fff;
    font-family: "Roboto Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 30px;
    text-transform: uppercase;
    white-space: nowrap;
    cursor: pointer;
    transition: background-color 0.4s;
}
.ButtonBlueSmall-news:hover {
    color: #000;
    background-color: rgba(191, 211, 254, 1);
}
.ButtonBlueSmall-news mark {
    background: transparent;
    color: #b7c9db;
}

.Newss_footer {
    position: relative;
    z-index: 3;
    flex: 0 0 auto;
    padding: 0 20px 16px; 
}

.Newss_tag {
    display: inline-block;
    margin: 4px 0;
    vertical-align: top; 
}
.Newss_tag:not(:last-child) {
    margin-right: 8px; 
}

.Newss_viewNewss {
    position: absolute;
    top: 105px;
    left: 20px;
    z-index: 3; 
}

.Newss_viewNewssIcon {
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background: #23aef9 url(/public/static/portal/images/mod-view-icon.svg) no-repeat center;
    cursor: pointer;
    transition: background-color 0.4s; 
}
.Newss_viewNewssIcon:hover {
    background-color: #28b0ff; 
}

.Newss_viewNewssLabel {
    position: absolute;
    top: 17px;
    left: 50px;
    padding: 0 0 0 10px;
    color: #23c7f9;
    font-size: 11px;
    line-height: 15px;
    text-transform: uppercase;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.4s; 
}
.Newss_viewNewssIcon:hover + .Newss_viewNewssLabel {
    opacity: 1; 
}


/* текст только в указанное количество строк далее пишется ... */
.line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.line-clamp-4 {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
  
  