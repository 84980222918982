.base {
    height: 60px; /* 60px */
    background-color: var(--main-color); 
}
sup {
    vertical-align: super;
    margin-left: 3px;
}
  
.inner {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    box-sizing: border-box;
    height: 100%;
    margin: 0 auto; 
}
@media all and (max-width: 1365px) {
    .inner {
        width: 1122px; /* 1022px 1122px */
        padding: 0 28px; 
    }
}
@media all and (min-width: 1366px) and (max-width: 1407px) {
    .inner {
        width: 1366px;
        padding: 0 40px; 
    }
}
@media all and (min-width: 1408px) {
    .inner {
        width: 1408px;
        padding: 0 40px; 
    }
}
  
.logo {
    display: inline-block;
    width: 16px;
    height: 16px; /* 16px 21px */
    margin: 0 45px 0 0;
    background: url(/public/static/portal/images/nav-logo.png) center no-repeat;
    vertical-align: top; 
}
  
.link {
    position: relative;
    color: var(--all-text-color); /* e0ddce rgb(255,255,255, 0.9)     rgb(206,212,224,1) */
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
    text-transform: uppercase;
    transition: color 0.4s; 
}
.link:not(:last-child) {
    margin: 0 40px 0 0; 
}
.link::after {
    position: absolute;
    right: 0;
    bottom: -23px; /* -26px */
    left: 0;
    height: 2px;
    opacity: 0;
    content: '';
    transition: opacity 0.4s, box-shadow 0.4s; 
}
.link:hover {
    color: var(--link-hover-color); /* #f9f5e1 */
}
.link:hover::after {
    background-color: var(--link-hover-after-color); /* #e4deb9 */
    opacity: 1; 
}
.link.isActive::after {
    box-shadow: 0 0 20px 1px var(--link-active-color);
    background-color: var(--main-blue-color);
    /* box-shadow: 0 0 20px 1px rgba(255, 84, 0, 0.8);
    background-color: #f9b723; */
    opacity: 1; 
}
  
.counter {
    position: absolute;
    top: -3px;
    right: -9px;
    height: 12px;
    padding: 0 3px;
    border-radius: 6px;
    box-shadow: 0 0 10px 0 #ff5400;
    background: var(--main-blue-color); /* #f9b723 */
    color: var(--white-color); /* #050505 */
    font-size: 11px; 
}

.search {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); 
}
@media all and (max-width: 1365px) {
    .search {
        right: 140px; 
    }
}
@media all and (min-width: 1366px) and (max-width: 1407px) {
    .search {
        right: 145px; 
    }
}
@media all and (min-width: 1408px) {
    .search {
        right: 145px; 
    }
}
  
  

/* userMenu */
.Button-user-menu {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); 
    -webkit-user-select: none;
    user-select: none;
}
@media all and (max-width: 1365px) {
    .Button-user-menu {
        right: 28px; 
    } 
}
@media all and (min-width: 1366px) and (max-width: 1407px) {
    .Button-user-menu {
        right: 40px; 
    } 
}
@media all and (min-width: 1408px) {
    .Button-user-menu {
        right: 40px; 
    } 
}

.Button-user-menu__base {
    position: relative;
    width: 102px;
    height: 32px;
    color: var(--btn-user-menu-base-color);
    line-height: 32px;
}
.Button-user-menu__base::after {
    content: '';
    background-color: var(--main-color);
    width: 100px;
}
.granica::after {
    border-bottom: 2px solid var(--granica-after-color); /* rgba(39, 39, 41, 1) rgba(255, 39, 41, 0.95) */
}
.Button-user-menu__base.granica::before {
    content: '';
    position: absolute;
    top: -15px;
    left: 0;
    border-left: 1px solid var(--btn-user-menu-granica-before-color);
    height: 60px;
    z-index: 102;
}
.Button-user-menu__base.granica::after {
    content: '';
    position: absolute;
    top: -15px;
    right: 0;
    left: 0;
    border-right: 1px solid var(--btn-user-menu-granica-after-color);
    height: 60px;
    z-index: 101;
}
.t-btn-sign {
    display: inline-block;
    border: 0 none;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    background-image: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
}
.t-btn_btn {
    display: inline-block;
    height: 60px;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    font-weight: 700;
    background-image: none;
    height: 40px;
    padding-left: 0px;
    padding-right: 5px;
    margin-left: 5px;
}
.t-btn_index {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 102;
}
.user-opacity {
    filter: alpha(opacity=60);
    opacity: 0.6;
}
.user-opacity__up {
    filter: alpha(opacity=100);
    opacity: 1;
}
.cm-arrow-1 {
    font: 0/0 a;
    text-shadow: none;
    color: transparent;
    display: inline-block;
    filter: alpha(opacity=60);
    opacity: 0.6;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    background-position: 0 -530px;
    width: 7px;
    height: 4px;
    background-repeat: no-repeat;
    background-image: url(/public//static/portal/images/main_sprite_1.2.0.svg);
    -webkit-transition: opacity ease 0.1s;
    transition: opacity ease 0.1s;
    margin-left: 5px;
    vertical-align: middle;
    -webkit-perspective-origin: center;
    perspective-origin: center;
}
.js-cm-arrow-up {
    filter: alpha(opacity=100);
    opacity: 1;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
.cm-menu-select__wide img {
    content: url(/public/static/portal/images/user_menu__user_1.png);
    display: none;
}
@media all and (max-width: 1365px) {
    .cm-menu-select__wide img {
        display: inline-block;
    }
    .cm-menu-select__wide span {
        display: none;
    }
}