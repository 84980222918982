.base {
    position: relative;
    width: 32px;
    height: 32px;
    border: 1px solid var(--action-blue-color);
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.4s; 
}
.base::before, .base::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    content: '';
    transition: opacity 0.4s; 
}
.base::before {
    background-image: url(/public/static/portal/images/action-mute-subscribe.png);
    opacity: 1; 
}
.base::after {
    background-image: url(/public/static/portal/images/action-mute-subscribe-hover.png);
    opacity: 0; 
}
.base:hover {
    background-color: var(--action-blue-color); 
}
.base:hover::before {
    opacity: 0; 
}
.base:hover::after {
    opacity: 1; 
}
