.base {
    position: relative;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 50px;
    padding: 0 0 0 60px;
    color: var(--action-red-color); /* #db2d1b */
    font-size: 11px;
    line-height: 15px;
    text-transform: uppercase;
    cursor: pointer;
    transition: color 0.4s; 
}
.base::before, .base::after {
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    border: 1px solid var(--action-red-color); /* #db2d1b */
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    content: '';
    transition: background-color 0.4s, opacity 0.4s; 
}
.base::before {
    background-image: url(/public/static/portal/images/action-report.svg);
    opacity: 1; 
}
.base::after {
    background-image: url(/public/static/portal/images/action-report-hover.svg);
    opacity: 0; 
}
.base:hover:not(.isDisabled):not(.isCompleted) {
    color: var(--action-disabled-red-color); 
}
.base:hover:not(.isDisabled):not(.isCompleted)::before {
    opacity: 0; 
}
.base:hover:not(.isDisabled):not(.isCompleted)::after {
    opacity: 1; 
}
.base:hover:not(.isDisabled):not(.isCompleted)::before, .base:hover:not(.isDisabled):not(.isCompleted)::after {
    background-color: var(--action-red-color); /* #db2d1b */
}
.base.isCompleted:not(.isDisabled) {
    opacity: .3;
    cursor: default; 
}
.base.isCompleted:not(.isDisabled)::before {
    opacity: 0; 
}
.base.isCompleted:not(.isDisabled)::after {
    opacity: 1; 
}
.base.isCompleted:not(.isDisabled)::before, .base.isCompleted:not(.isDisabled)::after {
    border-color: var(--action-red-color); /* #db2d1b */
    background-color: var(--action-red-color); /* #db2d1b */
}
.base.isDisabled {
    width: 50px;
    padding: 0;
    opacity: .3;
    cursor: default; 
}
