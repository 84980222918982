.FooterLinks_link {
    position: relative;
    margin: 0 30px 0 0;
    /* color: #858271; */
    color: var(--all-text-color); /* #858271 */
    /* filter: alpha(opacity=40);
    opacity: 0.4; */
    font-size: 12px;
    text-decoration: none;
    text-transform: uppercase;
    transition: color 0.4s; 
}
.FooterLinks_link:hover {
    /* color: #e9e3be;  */
    color: var(--link-hover-color); /* 5e8dd2 */ 
}
.FooterLinks_link.FooterLinks_isExternal {
    padding: 0 15px 0 0; 
}
.FooterLinks_link.FooterLinks_isExternal::after {
    position: absolute;
    top: 0; /* 4px */
    right: 0;
    width: 9px;
    height: 9px;
    background: url(/public/static/portal/images/footer-links-external-ico.svg) center no-repeat;
    opacity: .5;
    content: '';
    transition: opacity 0.4s; 
}
.FooterLinks_link.FooterLinks_isExternal:hover::after {
    opacity: 1; 
}
  