.item {
    position: relative;
    margin: 0 0 0 342px;
    border-radius: 10px;
    box-shadow: none;
    background-color: var(--changelog-background-blue-4c-color); /* rgba(249, 245, 225, 0.04) */
    word-wrap: break-word;
    transition: background-color 0.4s, box-shadow 0.4s; 
}
@media all and (max-width: 1365px) {
    .item {
        width: 624px; 
    }
}
@media all and (min-width: 1366px) and (max-width: 1407px) {
    .item {
        width: 587px; 
    }
}
@media all and (min-width: 1408px) {
    .item {
        width: 587px; 
    }
}
.item.isDraggable {
    cursor: move; 
}
.item.isDraggable::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    border-radius: 10px;
    background: url(/public/static/portal/images/edit-changelog-list-item-hover.png) center no-repeat;
    opacity: 0;
    content: '';
    transition: opacity 0.4s;
    pointer-events: none; 
}
.item.isDraggable:hover {
    background-color: var(--main-color); /* --changelog-background-draggable-color */
}
.item.isDraggable:hover::after {
    opacity: 1; 
}
.dragged .item.isDraggable {
    box-shadow: 0 0 0 1px var(--action-blue-color), 0px 0px 40px 0px var(--btn-cooment-hover-color); /* f9b723    rgba(255, 84, 0, 0.3) */
    background-color: var(--main-color); /* --changelog-background-draggable-color */
}
.dragged .item.isDraggable::after {
    opacity: 1; 
}

.inner {
    padding: 17px 0;
    opacity: 1;
    transition: opacity 0.4s; 
}
.dragged .inner,
.item.isDraggable:hover .inner {
    opacity: .3; 
}

.title {
    margin: 0 20px 12px;
    color: var(--attention-mark-color); /* f9f5e1 */
    font-size: 14px;
    font-weight: 700;
    line-height: 18px; 
}

.content {
    margin: 0 20px;
    color: var(--changelog-gray-color); /* b8b8a2 */
    font-size: 14px;
    line-height: 18px; 
}

.previewRemove {
    position: absolute;
    top: -10px;
    right: -10px;
    z-index: 3; 
}

.previewEdit {
    position: absolute;
    top: -10px;
    right: 21px;
    z-index: 2; 
}
