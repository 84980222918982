.NotificationBlockedUser_inner {
    display: flex;
    flex-flow: row nowrap;
    align-content: center;
    align-items: center;
    justify-content: center; 
}
  
.NotificationBlockedUser_icon {
    flex: 0 0 61px;
    width: 61px;
    height: 61px;
    background: url(/public/static/portal/images/notification-blocked-user-icon.png) center no-repeat; 
}

.NotificationBlockedUser_content {
    flex: 1 1 100%;
    box-sizing: border-box;
    padding: 0 20px; 
}

.NotificationBlockedUser_button {
    flex: 0 0 auto;
    padding: 0 10px; 
}
  