.base {
    display: flex;
    flex-flow: row nowrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    min-height: 300px;
    color: var(--all-text-color); /* #e9e3be */
    font-size: 20px; 
}
.base.isSmall {
    height: 195px;
    padding: 0 0 33px; 
}
