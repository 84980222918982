.ScrollArea_scrollTrack {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 4px;
    border-radius: 2px;
    background-color: rgba(255, 255, 255, 0.05); 
}
.ScrollArea_scrollTrack.ScrollArea_isDropdown {
    bottom: 32px; 
}

.ScrollArea_scrollThumb {
    border-radius: 2px;
    background-color: rgba(190, 211, 233, 0.5); /* rgba(233, 227, 190, 0.5) */
}
