

.cm_notifications_item {
    background: var(--cm-notifications-item-background-color); /* 0e0e0e */
    border-top: 1px solid var(--cm-notifications-item-border-color); /* 1d1d1d */
    line-height: 22px;
    padding: 25px 25px 28px;
    position: relative;
    text-align: left;
}
@media all and (max-width: 1023px) {
    .cm_notifications_item {
        background:var(--cm-notifications-item-background2-color); /* 171717 #1c1c1c  454545*/
        border-color: var(--cm-notifications-item-border2-color);
    }
}
.cm_notifications_item:hover {
    border-color: var(--cm-notifications-item-border-hover-color); /* 1d1d1d 373737 454545 */
    border-color: var(--cm-notifications-item-border2-hover-color);
    z-index: 1;
}
.cm_notifications_item:hover:before {
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    top: -1px;
    bottom: 0;
    background: var(--black-color);/*var(--cm-notifications-item-background-hover-before-color); /* 151515 */
    background: var(--black-color);/*var(--cm-notifications-item-background2-hover-before-color);*/
}
@media all and (max-width: 1023px) {
    .cm_notifications_item:hover {
        background: var(--black-color); /* 171717 */
        border-color: var(--cm-notifications-item-border2-color);
    }
    .cm_notifications_item:hover:before {
        content: none;
    }
}
.cm_notifications_item:first-child {
    border: none;
}
@media all and (max-width: 1023px) {
    .cm_notifications_item {
        max-width: 100%;
    }
}
.cm_notifications_item__unread {
    opacity: 1;
}
.cm_notifications_item__unread:before {
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    top: -1px;
    bottom: 0;
    background: var(--cm-notifications-item-unread-before-color);
    background: var(--cm-notifications-item-unread2-before-color)
}
.cm_notifications_item__hidden {
    display: none;
}

.cm_notifications_icon_holder {
    -webkit-transition: opacity ease 0.1s;
    transition: opacity ease 0.1s;
    margin: 7px 0 15px 21px;
    float: left;
    width: 18px;
    position: relative;
    opacity: 0.6;
}
.cm_not-touch .cm_menu_item__notifications:hover .cm_notifications_icon_holder {
    opacity: 1;
}
@media all and (max-width: 1023px) {
    .cm_not-touch .cm_menu_item__notifications:hover .cm_notifications_icon_holder {
        opacity: 0.6;
    }
}

.cm_notifications_icon {
    font: 0/0 a;
    text-shadow: none;
    color: transparent;
    position: relative;
}
.cm_notifications_icon__wg {
    background-repeat: no-repeat;
}
.cm_notifications_icon__ccs {
    background-position: 0 -1760px;
    width: 34px;
    height: 42px;
    background-repeat: no-repeat;
    background-image: url(/public/static/portal/images/main_sprite_1.1.23.svg); /* url(/public/static/portal/images/main_sprite_1.1.23.svg); */
    margin-left: -17px;
}
.cm_not_svg .cm_notifications_icon__ccs,.cm_layout__china.cm_not_svg .cm_notifications_icon__ccs {
    background-image: url(/public/static/portal/images/main_sprite_1.1.23.png); /* url(/public/static/portal/images/main_sprite_1.1.23.svg); */
}
.cm_notifications_icon__wot {
    background-position: 0 -2690px;
    width: 29px;
    height: 37px;
    background-repeat: no-repeat;
    background-image: url(/public/static/portal/images/main_sprite_1.1.23.svg);
    margin-left: -14.5px;
}
.cm_not_svg .cm_notifications_icon__wot,.cm_layout__china.cm_not_svg .cm_notifications_icon__wot {
    background-image: url(/public/static/portal/images/main_sprite_1.1.23.png);
}

.cm_notifications_type {
    font: 0/0 a;
    text-shadow: none;
    color: transparent;
    position: absolute;
    left: 100%;
    bottom: -6px;
    z-index: 1;
    padding-right: 1px;
}
.cm_notifications_type__important {
    background-position: 0 -960px;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-image: url(/public/static/portal/images/main_sprite_1.1.23.svg);
    margin-left: -10px;
}
.cm_not_svg .cm_notifications_type__important,.cm_layout__china.cm_not_svg .cm_notifications_type__important {
    background-image: url(/public/static/portal/images/main_sprite_1.1.23.png)
}
.cm_notifications_type__message {
    background-position: 0 -1020px;
    width: 20px;
    height: 15px;
    background-repeat: no-repeat;
    background-image: url(/public/static/portal/images/main_sprite_1.1.23.svg);
    margin-left: -10px
}
.cm_not_svg .cm_notifications_type__message,.cm_layout__china.cm_not_svg .cm_notifications_type__message {
    background-image: url(/public/static/portal/images/main_sprite_1.1.23.png)
}
.cm_notifications_type__alert {
    background-position: 0 -930px;
    width: 22px;
    height: 20px;
    background-repeat: no-repeat;
    background-image: url(/public/static/portal/images/main_sprite_1.1.23.svg);
    margin-left: -11px
}
.cm_not_svg .cm_notifications_type__alert,.cm_layout__china.cm_not_svg .cm_notifications_type__alert {
    background-image: url(/public/static/portal/images/main_sprite_1.1.23.png)
}
.cm_notifications_type__success {
    background-position: 0 -1080px;
    width: 13px;
    height: 12px;
    background-repeat: no-repeat;
    background-image: url(/public/static/portal/images/main_sprite_1.1.23.svg);
    margin-left: -6.5px
}
.cm_not_svg .cm_notifications_type__success,.cm_layout__china.cm_not_svg .cm_notifications_type__success {
    background-image: url(/public/static/portal/images/main_sprite_1.1.23.png)
}
.cm_notifications_type__info {
    background-position: 0 -990px;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-image: url(/public/static/portal/images/main_sprite_1.1.23.svg);
    margin-left: -10px
}
.cm_not_svg .cm_notifications_type__info,.cm_layout__china.cm_not_svg .cm_notifications_type__info {
    background-image: url(/public/static/portal/images/main_sprite_1.1.23.png)
}

.cm_notifications_info {
    margin-left: 68px;
    position: relative;
    font-size: 14px;
}
@media all and (max-width: 1023px) {
    .cm_notifications_info {
        font-size:16px;
        line-height: 22px;
    }
}

.cm_text_link {
    -webkit-transition: color ease 0.1s, text-shadow ease 0.1s;
    transition: color ease 0.1s, text-shadow ease 0.1s;
    color: var(--cm-text-link-color);
    display: inline-block;
    position: relative;
    text-decoration: none;
    line-height: inherit;
    font-size: inherit;
}

.cm_notifications_time {
    color: var(--cm-notifications-time-color);
    display: block;
    margin-top: 9px;
}

.cm_close {
    color: var(--cm-notifications-close-color);
    opacity: 0;
    position: absolute;
    top: 5px;
    right: 20px;
    text-decoration: none;
}
.cm_close:hover {
    color: var(--cm-notifications-close-hover-color);
    text-decoration: none;
}
.cm_notifications_item:hover .cm_close {
    opacity: 1;
}
.cm_notifications_item .cm_close {
    opacity: 0;
}
.cm_not-touch .cm_notifications_item .cm_close {
    opacity: 0;
}
.cm_not-touch .cm_notifications_item:hover .cm_close {
    opacity: 1;
}

