.Paginator_base {
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style: none;
    color: rgba(206, 212, 224, 1); /* #e9e2be */
    vertical-align: top;
    user-select: none; 
}

.Paginator_page {
    display: inline-block;
    width: 32px;
    height: 32px;
    margin: 0 6px;
    border: 1px solid rgba(191, 211, 254, 0.2); /* rgba(228, 222, 185, 0.2) */
    border-radius: 50%;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.4);
    background-image: none;
    background-repeat: repeat-x;
    background-position: center;
    font-size: 12px;
    line-height: 32px;
    text-align: center;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
    text-transform: uppercase;
    vertical-align: top;
    cursor: pointer;
    transition: border-color 0.4s; 
}
.Paginator_page:hover {
    border: 1px solid rgba(191, 211, 254, 1); /* #e4deb9 */ 
}
.Paginator_page.Paginator_isActive {
    box-shadow: none;
    background-color: rgba(191, 211, 254, 1); /* #e4deb9 */
    color: #000;
    cursor: default; 
}

.Paginator_prev,
.Paginator_next {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 32px;
    border: 1px solid transparent;
    border-radius: 50%;
    vertical-align: middle;
    cursor: pointer;
    transition: border-color 0.4s; }
.Paginator_prev.Paginator_isDisabled,
.Paginator_next.Paginator_isDisabled {
    cursor: default; 
}
.Paginator_prev:not(.Paginator_isDisabled):hover,
.Paginator_next:not(.Paginator_isDisabled):hover {
    border: 1px solid rgba(191, 211, 254, 1); /* #e4deb9 */
}
.Paginator_prev::before,
.Paginator_next::before {
    position: absolute;
    top: 12px;
    padding: 3px;
    border: solid rgba(228, 222, 185, 0.2);
    border-width: 0 2px 2px 0;
    line-height: 32px;
    content: '';
    transition: border-color 0.4s; 
}
.Paginator_prev:not(.Paginator_isDisabled):hover::before,
.Paginator_next:not(.Paginator_isDisabled):hover::before {
    border: solid rgba(191, 211, 254, 1); /* #e4deb9 */
    border-width: 0 2px 2px 0; 
}

.Paginator_prev {
    margin: 0 6px 0 0; 
}
.Paginator_prev::before {
    left: 13px;
    transform: rotate(135deg); 
}

.Paginator_next {
    margin: 0 0 0 6px; 
}
.Paginator_next::before {
    right: 13px;
    transform: rotate(-45deg); 
}

.Paginator_hellip {
    position: relative;
    top: 20px;
    display: inline-block;
    margin: 0 2px;
    font-size: 16px;
    vertical-align: top; 
}
