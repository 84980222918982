.base {
    width: 21px;
    height: 21px;
    border-radius: 50%;
    background-color: var(--main-color); /* 050505 */
    font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
    cursor: pointer; 
}
.base::before, .base::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    content: '';
    transition: opacity 0.4s; 
}
.base::before {
    background-image: url(/public/static/portal/images/preview-remove.svg);
    opacity: 1; 
}
.base::after {
    background-image: url(/public/static/portal/images/preview-remove-hover.svg);
    opacity: 0; 
}
.base:hover::before {
    opacity: 0; 
}
.base:hover::after {
    opacity: 1; 
}
