.Screenshots_base {
    margin: 0 0 58px;
    border-radius: 10px;
    background-color: #050505; 
}

.Screenshots_image {
    display: inline-block;
    width: 100%;
    border-radius: 10px;
    vertical-align: top; 
}

.Screenshots_view {
    display: inline-block;
    border-radius: 10px;
    vertical-align: top;
    cursor: pointer; 
}
.Screenshots_view img {
    display: inline-block;
    border-radius: 10px;
    vertical-align: top; 
}

.Screenshots_previews {
    position: relative;
    z-index: 1;
    padding: 20px; 
}
.Screenshots_previews::after {
    position: absolute;
    right: 0;
    bottom: 100%;
    left: 0;
    height: 59px;
    background: url(/public/static/portal/images/screenshots-shadow.png) center repeat-x;
    content: ''; 
}

.Screenshots_preview {
    position: relative;
    display: inline-block;
    border-radius: 10px;
    vertical-align: top;
    cursor: pointer; 
}
.Screenshots_preview:not(:last-child) {
    margin: 0 22px 0 0; 
}
.Screenshots_preview::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 10px;
    content: ''; 
}
.Screenshots_preview.Screenshots_isActive::after {
    box-shadow: inset 0 0 0 2px #0051ff, 0 0 20px 0 rgba(255, 84, 0, 0.3); 
}
.Screenshots_preview img {
    display: inline-block;
    border-radius: 10px;
    vertical-align: top; 
}

.Screenshots_counter {
    position: absolute;
    top: -20px;
    left: 20px;
    z-index: 2;
    color: #8797a2; /* #a29e87 */
    font-size: 14px;
    line-height: 25px; 
}
.Screenshots_counter > span {
    color: #c8d7eb; /* #ebe8c8 */ 
    font-weight: 700; 
}

.Screenshots_lightboxCounter {
    padding: 40px 0;
    color: #726f5f;
    font-size: 18px;
    text-align: center;
    white-space: nowrap; 
}
.Screenshots_lightboxCounter > span {
    color: #c8d7eb; /* #ebe8c8 */ 
    font-weight: 700; 
}
