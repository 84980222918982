.base {
    width: 100%;
    margin: 0 0 20px;
    color: rgba(206, 212, 224, 1); /* #e9e3be */
    font-size: 24px;
    font-weight: 700;
    line-height: 29px; 
}
.base mark {
    background-color: transparent;
    color: #0051ff; /* #f9b723 */ 
}
