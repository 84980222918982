.base {
    height: 18px;
    padding: 0 10px;
    border: 1px solid;
    border-radius: 16px;
    border-color: var(--btn-cooment-color);
    background-color: transparent;
    color: var(--white-color);
    font-family: "Roboto Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: 400;
    text-transform: none;
    white-space: nowrap;
    cursor: pointer;
    transition: background-color 0.4s;
}
.base:focus {
    outline: none; 
}
.base:hover {
    background-color: var(--btn-cooment-hover-color);
}
.base.isWide {
    width: 100%; 
}
.base__plus {
    position: relative;
    padding-left: 48px; 
}
.base__plus::before {
    position: absolute;
    top: 50%;
    left: 30px;
    width: 10px;
    height: 10px;
    background: url(/public/static/portal/images/button-yellow-small-plus.svg) center no-repeat;
    content: '';
    transform: translateY(-50%); 
}
.base mark {
    background: transparent;
    color: var(--black-color); 
}
  
.content {
    position: relative;
    z-index: 2;
}

@keyframes blink-button {
    0% {
        -webkit-transform: translateX(-87px) skew(-32deg);
        transform: translateX(-87px) skew(-32deg)
    }

    50%,to {
        -webkit-transform: translateX(100%) skew(-32deg);
        transform: translateX(100%) skew(-32deg)
    }
}

.glitch {
    position: relative;
    overflow: hidden;
    opacity: 1;
}
.glitch:before {
    width: 100%;
    height: 100%;
    border-left: 48px solid #005fff; /* #ff8200 */
    left: 0;
}
.glitch:after {
    width: 115%;
    height: 115%;
    border-left: 14px solid #005fff; /* #ff8200 */
    left: 58px;
}

.glitch:after, .glitch:before {
    content: "";
    position: absolute;
    top: 0;
    will-change: transform;
    -webkit-animation: blink-button 3s infinite ease;
    animation: blink-button 3s infinite ease;
}
