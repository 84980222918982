.base {
    height: 66px; /* 66px 150px */
    background-color: var(--main-color);
    user-select: none;
}

.block {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    box-sizing: border-box;
    height: 100%;
    margin: 0 auto;
}
@media all and (max-width: 1365px) {
    .block {
      width: 1122px; /* 1022px */
      padding: 0 28px; } 
}
@media all and (min-width: 1366px) and (max-width: 1407px) {
    .block {
      width: 1366px;
      padding: 0 40px; } 
}
@media all and (min-width: 1408px) {
    .block {
      width: 1408px;
      padding: 0 40px; } 
}

.logo {
    display: inline-block;
    margin: 0 45px 0 0;
    vertical-align: top;
    color: var(--white-color);
    position: relative;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 1.5px;
    line-height: 1;
    text-decoration: none;
    text-transform: uppercase;
    transition: color 0.4s;
}
.Footer_logo {
    display: inline-block;
    width: 79px;
    height: 21px;
    margin: 0 30px 0 0;
    background: url(/public/static/portal/images/footer-logo.png) center no-repeat;
    vertical-align: top; 
}
.Footer_social__links {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    display: inline-block;
}

.social_links {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); 
    margin-right: 10px;
    margin-left: 5px;
}
@media all and (max-width: 1365px) {
    .social_links {
        right: 28px; } 
}
@media all and (min-width: 1366px) and (max-width: 1407px) {
    .social_links {
        right: 40px; } 
}
@media all and (min-width: 1408px) {
    .social_links {
        right: 40px; } 
}
.social_links__item {
    display: inline-block;
    filter: alpha(opacity=40);
    opacity: 0.4;
    margin-right: 4px;
    -webkit-transition: opacity ease-in-out 0.2s;
    -moz-transition: opacity ease-in-out 0.2s;
    -o-transition: opacity ease-in-out 0.2s;
    transition: opacity ease-in-out 0.2s;
}
.social_links__item:hover {
    filter: alpha(opacity=100);
    opacity: 1;
}

.copy {
    color: var(--footer-copy-color);
    /* margin-top: 21px; */
    margin-top: 8px;
    font-size: 12px;
}
.link {
    position: relative;
    margin: 0 30px 0 0;
    color: var(--all-text-color); /* #858271 */
    filter: alpha(opacity=40);
    opacity: 0.4;
    font-size: 12px;
    text-decoration: none;
    text-transform: uppercase;
    transition: color 0.4s;
}