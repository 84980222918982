.base {
    box-sizing: border-box;
    width: 100%;
    padding: 10px 20px;
    /*overflow: hidden;*/
    overflow-y: auto;
    scrollbar-color: rgba(190, 216, 233, 0.4) transparent; /* rgba(5, 5, 5, 0.4) */
    scrollbar-width: thin;
    border: 1px solid transparent;
    border-radius: 10px;
    background-color: rgba(5, 5, 5, 0.4);
    color: rgba(190, 216, 233, 0.6); /* #e9e3be */
    font-family: "Roboto Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 20px;
    vertical-align: top;
    resize: none;
    transition: background-color 0.4s, border-color 0.4s; 
}
.base:hover:not(.isDisabled), .base:focus:not(.isDisabled) {
    outline: none;
    color: rgba(190, 216, 233, 1);
    background-color: #050505; /* #050505 rgba(39, 39, 41, 0.95) 1c1c1c */ 
}
.base.isDisabled {
    opacity: .3; 
}
.base.isError {
    border-color: #db2d1b;
    background-color: #050505; /* #050505 rgba(39, 39, 41, 0.95) 1c1c1c */ 
}
