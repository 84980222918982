.base {
    position: relative;
    width: 280px; /* 402px */
    height: 32px; /* 42px */
    color: var(--search-base-color); 
    line-height: 32px; /* 42px */
}
@media (max-width: 1365px) {
    .base {
        width: 200px;
    }
}

.input {
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 0 20px 0 38px; /* 0 38px 0 20px */
    border: none;
    border-radius: 16px;
    background: none;
    background-color: var(--search-background-input-color);
    color: inherit;
    font-family: inherit;
    font-size: 14px;
    line-height: inherit;
    transition: background-color 0.4s; 
}
.input:hover {
    background-color: var(--black-color); 
}
.base.isActive .input, .input:focus {
    outline: none;
    box-shadow: 0 0 20px 0 var(--search-input-focus-color); /* rgba(255, 84, 0, 0.2) */
    background-color: #2367f9; /* f9b723 */
    color: var(--black-color); 
}

.button {
    position: absolute;
    top: 50%;
    left: 10px; /* right: 10px; */
    z-index: 3;
    width: 24px;
    height: 24px;
    cursor: pointer;
    transform: translateY(-50%); 
}
.button::before, .button::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: center;
    content: '';
    transition: opacity 0.4s; 
}
.button::before {
    background-image: url(/public/static/portal/images/search-button.svg);
    opacity: 1; 
}
.button::after {
    background-image: url(/public/static/portal/images/search-button-hover.svg);
    opacity: 0; 
}
.base.isActive .button::before,
.input:focus + .button::before {
    opacity: 0; 
}
.base.isActive .button::after,
.input:focus + .button::after {
    opacity: 1; 
}

.placeholder {
    position: absolute;
    top: 0;
    right: 20px; /* 34px */
    bottom: 0;
    left: 44px; /* 20px */
    z-index: 2;
    display: none;
    padding: 1px;
    color: var(--search-placeholder-color); 
    font-size: 11px;
    line-height: inherit;
    text-transform: uppercase;
    pointer-events: none; 
}
.placeholder.isVisible {
    display: block; 
}
