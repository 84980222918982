.content {
    display: flex;
    /*height: 100%;*/
    overflow: hidden;
    padding: 16px 24px 24px 0;
}

.section {
    flex: 2;
    background-color: var(--infoblock-section-color);
    border-radius: 32px;
    padding: 32px 32px 0 32px;
    margin: 10px 0 10px 0;
    overflow: hidden;
    /*height: 100%;*/
    display: flex;
    flex-direction: column;
}


.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    color: var(--all-text-color);
}

.header p {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
    opacity: 0.9;
    margin: 0;
    color: var(--all-text-color);
    text-transform: uppercase;
}

.header .time {
    font-size: 16px;
}


.section_line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 32px;
}

.mods_status {
    display: flex;
}

.mods_status__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 32px;
}

.mods_status__item:hover {
    cursor: pointer;
}

.status_item__number {
    font-size: 32px;
    line-height: 48px;
    font-weight: 700;
    color: var(--all-text-color);
}

.status_item__type {
    position: relative;
    font-size: 11px;
    text-transform: uppercase;
    /*padding-right: 24px;*/
    color: var(--infoblock-secondary-color);
}

.mods_actions {
    display: flex;
    align-items: center;
}


.boxes.jsGridView {
    display: flex;
    flex-wrap: wrap;
    overflow-y: visible;
}