.News_base {
    height: 100%; 
}
  
.News_section {
    position: relative; 
}
.News_section:not(:last-child) {
    margin-bottom: 50px; 
}
.News_section__filtered {
    display: flex;
    flex-flow: column nowrap;
    box-sizing: border-box;
    height: 100%; 
}

.News_filteredHead {
    position: relative;
    z-index: 2;
    flex: 0 0 auto; 
}

.News_filteredBody {
    position: relative;
    z-index: 1;
    flex: 1 1 100%;
    /* stylelint-disable order/properties-order */
    -ms-flex: 1 0 auto;
    /* stylelint-enable */ 
}

.News_title {
    display: inline-block;
    margin: 0 0 38px;
    vertical-align: top; 
}

.News_reset {
    position: absolute;
    top: -1px;
    left: 100%;
    margin: 0 0 0 7px; 
}

.News_filter {
    position: absolute;
    top: -2px;
    right: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start; 
}

.News_toggle {
    margin: 0 30px 0 0; 
}

.News_section__filtered .News_inner {
    height: 100%; 
}

@media all and (max-width: 1365px) {
    .News_inner {
        margin-right: -15px;
        margin-left: -15px; 
    } 
}

@media all and (min-width: 1366px) and (max-width: 1407px) {
    .News_inner {
        margin-right: -13px;
        margin-left: -13px; 
    } 
}

@media all and (min-width: 1408px) {
    .News_inner {
        margin-right: -20px;
        margin-left: -20px; 
    }
}

.News_block {
    margin: 0 0 -50px; 
}

.News_contentBlock {
    display: inline-block;
    vertical-align: top; 
}
@media all and (max-width: 1365px) {
    .News_contentBlock {
        margin: 0 15px 50px; 
    }
}
@media all and (min-width: 1366px) and (max-width: 1407px) {
    .News_contentBlock {
        margin: 0 13px 50px; 
    }
}
@media all and (min-width: 1408px) {
    .News_contentBlock {
        margin: 0 20px 50px; 
    }
}

.News_button {
    margin: 40px 0 0;
    text-align: center; 
}