.container_user_menu {
    display: none; /* for popup */
    position: absolute;
    width: 436px; /* 608px */
    /*height: 300px;*/
    max-height: 300px;
    margin: 0px 1px;  
    /*right: 0; /* for popup */
    top: 59px;
    float: right;
    border-right: 1px solid var(--container-umenu-border-color); /* #313335 */
    border-left: 1px solid var(--container-umenu-border-color);
    border-bottom: 1px solid var(--container-umenu-border-color);
    /*box-shadow: 0 0 25px rgba(0, 0, 0, 0.4);*/
    text-align: left;
    font-size: 14px;
    line-height: 18px;
    background: var(--main-color);
    z-index: 100; 
}
@media all and (min-width: 1408px) {
    .container_user_menu {
        right: 40px;
    }  
}
@media all and (min-width: 1366px) and (max-width: 1407px) {
    .container_user_menu {
        right: 40px;
    } 
}
@media all and (max-width: 1365px) {
    .container_user_menu {
        right: 28px;
    } 
}
.cm_border__top {
    border-top: 1px solid;
    border-image-slice: 1;
    border-width: 1px;
}
@media all and (max-width: 1365px) {
    .cm_border__top {
        border-image-source: linear-gradient(to right, var(--container-umenu-border-color) 336px, transparent 336px 436px, var(--container-umenu-border-color) 436px); /* (to right, #454545 307px, transparent 308px 406px, #454545 407px) */ 
    } 
}
@media all and (min-width: 1366px) and (max-width: 1407px) {
    .cm_border__top {
        border-image-source: linear-gradient(to right, var(--container-umenu-border-color) 336px, transparent 336px 436px, var(--container-umenu-border-color) 436px); /* (to right, #454545 296px, transparent 296px 396px, #454545 396px) */
    }
}
@media all and (min-width: 1408px) {
    .cm_border__top {
        border-image-source: linear-gradient(to right, var(--container-umenu-border-color) 336px, transparent 337px 436px, var(--container-umenu-border-color) 436px); /* (to right, #454545 296px, transparent 296px 396px, #454545 396px) */
    } 
}

.wrapper_user_menu {
    width: 100%;
    height: 100%;
}

.notifications_panel {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    text-align: left;
    line-height: 53px;
    font-size: 13px;
    padding: 0 25px;
    border-bottom: 1px solid;
    border-color: var(--notification-panel-border-color);
}

.notifications_panel__text {
    color: var(--all-text-color); /* #707273 */
    font-size: 14px;
    text-transform: uppercase;
}
.link {
    -webkit-transition: color ease 0.1s, text-shadow ease 0.1s;
    transition: color ease 0.1s, text-shadow ease 0.1s;
    color: var(--all-text-color); /* #707273 */
    display: inline-block;
    position: relative;
    text-decoration: none;
    line-height: inherit;
    font-size: inherit;
}
.link:not(:last-child) {
    margin-right: 10px;
}
.link:hover {
    color: var(--notification-link-hover-color); /* #e5e5e5 */
    text-decoration: none;
}
.link::after {
    filter: alpha(opacity = 0);
    opacity: 0;
    content: "";
    border-bottom: 0 solid;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    margin-top: 8px;
    transition: .1s ease opacity;
}
.link:hover::after {
    filter: alpha(opacity = 80);
    opacity: 0.8;
    border-bottom-width: 1px;
}
.notifications_panel__link {
    float: right;
    font-size: 14px;
}

.cursor_pointer {
    cursor: pointer;
}
.cursor_default {
    cursor: default;
}

.link__important {
    color: var(--notification-link-important-color); /* #d19834 */
}
.notifications_list__show .notifications_new {
    display: inline-block;
}
.notifications_new {
    -webkit-transition: opacity ease 0.1s;
    transition: opacity ease 0.1s;
    filter: alpha(opacity = 0);
    opacity: 0;
    display: none;
    margin: 0 5px 0;
}
.notifications_new__active {
    transition: opacity ease 0.1s;
    filter: alpha(opacity=100);
    opacity: 1;
}
.notifications_new__hidden {
    height: 0;
    display: none;
}
.notifications_list__show .notifications_new__hidden {
    display: inline-block;
}
.notifications_unread_number {
    transition: opacity ease 0.1s;
    filter: alpha(opacity=0);
    opacity: 0;
}
.notifications_unread_number__hidden {
    filter: alpha(opacity=0);
    opacity: 0;
    -webkit-animation: 0.2s hide 0s;
    animation: 0.2s hide 0s;
}
.notifications_unread_number__active {
    filter: alpha(opacity=100);
    opacity: 1;
    -webkit-animation: 0.2s show 0s;
    animation: 0.2s show 0s;
}

.notifications_empty {
    color: var(--notifications-empty-color);
    font-size: 14px;
    padding: 47px 50px 40px;
    text-align: center;
    margin-top: 53px;
    display: none;
}
.notifications_empty__show { /* .notifications_empty */
    display: block;
}
.notifications_empty__active {
    text-transform: none;
}
.notifications_empty__hidden {
    text-transform: none;
}
.notifications_empty__logo {
    background-position: 0 -140px;
    width: 65px;
    height: 80px;
    background-repeat: no-repeat;
    background-image: url(/public/static/portal/images/main_sprite_1.1.23.svg);
    filter: alpha(opacity=20);
    opacity: 0.2;
    margin: 0 auto 20px;
}

.notifications_settings {
    padding: 20px 30px 28px 37px;
    text-align: left;
    /*display: none;*/
    font-size: 14px;
}
@media all and (max-width: 1023px) {
    .notifications_settings {
        padding:8px 14px 22px;
        border-bottom: 1px solid var(--notifications-settings-color);
        font-size: 16px;
        margin-bottom: -1px;
    }
}
.notifications_settings__show .notifications_settings {
    display: block;
}
.notifications_settings__title {
    text-transform: uppercase;
    font-weight: normal;
    color: var(--all-text-color); /* 707276 */
    display: block;
}
.notifications_settings__back {
    width: 60px;
    display: block;
    margin: 0 0 0 auto;
    text-align: center;
    font-size: 14px;
    line-height: 15px;
    text-transform: uppercase;
    border-color: var(--notifications-settings-color);
    color: var(--notifications-back-color);
    color: var(--notifications-back2-color);
}
@media all and (max-width: 1023px) {
    .notifications_settings__back {
        width:auto;
        margin-top: 0;
        background: transparent;
        color: var(--notifications-settings-back-color);
        text-transform: none;
        line-height: 25px;
        font-size: 16px;
    }
    .notifications_settings__back:hover,.notifications_settings__back:active {
        background: var(--notifications-settings-back-active-background-color);
        border-color: var(--notifications-settings-back-active-background-color);
        color: var(--black-color);
    }
}
.notifications_settings__back:hover {
    border-color: var(--notifications-settings-back-hover); /* ffc233 */
    background: var(--notifications-settings-back-hover); 
    color: var(--black-black-color);
}
@media all and (max-width: 1023px) {
    .notifications_settings__back:hover {
        background:var(--notifications-settings-back-active-background-color);
        border-color: var(--notifications-settings-back-active-background-color);
        color: var(--black-color);
    }
}
@media all and (max-width: 1023px) {
    .notifications_settings__form {
        display: table;
        width: 100%;
        padding-top: 13px;
    }
}
.notifications_settings__item {
    position: relative;
    margin-bottom: 24px;
    padding: 11px 0 10px;
    border-bottom: 1px solid var(--notifications-settings-item-border-color); /* 272727 */
    border-bottom: 1px solid var(--notifications-settings-item-border2-color); /* 39 39 39 */
}
@media all and (max-width: 1023px) {
    .notifications_settings__item {
        padding-top:0;
        padding-bottom: 2px;
        margin: 0;
        border: 0;
        display: table-cell;
        vertical-align: top;
    }
}
@media all and (max-width: 767px) {
    .notifications_settings__item {
        display:block;
    }
}
.notifications_settings__message {
    -webkit-transition: opacity ease 0.1s;
    transition: opacity ease 0.1s;
    filter: alpha(opacity=0);
    opacity: 0;
    float: left;
    color: var(--notifications-settings-message-color);
    width: 235px;
    line-height: 33px;
}
@media all and (max-width: 1023px) {
    .notifications_settings__message {
        float:none;
        display: table-cell;
        vertical-align: top;
        line-height: 25px;
        text-align: right;
    }
}
@media all and (max-width: 767px) {
    .notifications_settings__message {
        width:auto;
        text-align: left;
        display: block;
        margin-left: 26px;
        margin-bottom: 11px;
        line-height: 33px;
    }
}
.notifications_settings__message__active {
    filter: alpha(opacity=100);
    opacity: 1
}
.notifications_settings__check {
    position: absolute;
    left: -9999px;
    padding: 0;
    width: 13px;
    height: 13px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    margin-right: -13px;
    line-height: 25px;
    margin-top: 4px;
    left: 0\9;
}
.notifications_settings__check+.notifications_settings__label {
    padding-left: 26px;
    line-height: 25px;
    color: var(--notifications-settings-label-color);
    position: relative;
    display: inline-block;
    vertical-align: top;
}
.notifications_settings__check+.notifications_settings__label:before {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    content: '';
    position: absolute;
    width: 13px;
    height: 13px;
    left: 0;
    top: 6px;
    color: var(--notifications-settings-label-before-color); /* dba72e */
    border: 1px solid var(--notifications-settings-label-before-border-color);
    display: none\9;
}
.notifications_settings__check+.notifications_settings__label:after {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition: opacity ease 0.1s;
    transition: opacity ease 0.1s;
    filter: alpha(opacity=0);
    opacity: 0;
    content: '';
    position: absolute;
    top: 6px;
    left: 0;
    margin-top: 2px;
    margin-left: 3px;
    border-radius: 2px;
    height: 4px;
    width: 6px;
    border: solid var(--notifications-settings-label-before-color); /* dba72e */
    border-width: 0 0 2px 2px;
    display: none\9;
}
.notifications_settings__check:checked+.notifications_settings__label:after {
    filter: alpha(opacity=100);
    opacity: 1;
}

.button {
    -webkit-transition: all ease 0.1s;
    transition: all ease 0.1s;
    color: var(--notification-button-color); /* 707273 */
    border: 1px solid #333333;
    border-radius: 8px; /* 1px */
    font-size: 12px;
    min-width: 60px;
    padding: 8px 15px;
    text-align: center;
    text-decoration: none;
    position: relative;
    z-index: 10;
}
.button:hover {
    text-decoration: none;
    color: var(--black-black-color); /* 707273 */
}
.button__no_hover {
    pointer-events: none;
}

.show {
    display: block;
}