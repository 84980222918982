.base {
    display: inline-block;
    margin: 0 9px 0 0;
    color: var(--all-text-color); /* #e9e3be */
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    text-transform: uppercase;
    vertical-align: top;
    white-space: nowrap;
    transition: color 0.4s; 
}
.base.isLarge {
    font-size: 18px;
    line-height: 24px;
    white-space: normal; 
}
.base.isRequired::after {
    margin: 0 0 0 4px;
    color: var(--action-red-color);
    font-size: 20px;
    content: '*'; 
}
.base.isError {
    color: var(--action-red-color); 
}
