.base {
    display: flex;
    flex-flow: row nowrap;
    margin: 0 0 20px; 
}
  
.label {
    flex: 0 0 342px;
    margin: 4px 0 0; 
}
  
.field {
    flex: 1 1 100%; 
}
  