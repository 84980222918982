.base {
  display: flex;
  flex-flow: column nowrap;
  box-sizing: border-box;
  height: 100%;
  /* background-color: #1c1c1e; */
}
.header {
  position: relative;
  z-index: 2;
  flex: 0 0 60px; /* 66px */
}
/* header sticky */
.sticky {
  position: sticky; /* fixed */
  top: 0;
  /*width: 100%;*/
}
.body {
  position: relative;
  z-index: 1;
  flex: 1 1 100%;
  -ms-flex: 1 0 auto;
}
.footer {
  position: relative;
  z-index: 2;
  flex: 0 0 66px; /* 66px 150px 310px */
}

.PaddingTop-48px {
  padding-top: 48px;
}
.PaddingBottom-48px {
  padding-bottom: 48px;
}