.base {
    height: 50px;
    padding: 0 48px;
    border: 1px solid var(--btn-grays-20c-color); /* rgba(228, 222, 185, 0.2) */
    border-radius: 25px;
    background-color: transparent;
    color: var(--btn-grays-color); /* e4deb9 */
    font-family: "Roboto Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 48px;
    text-transform: uppercase;
    white-space: nowrap;
    cursor: pointer;
    transition: background-color 0.4s, color 0.4s; 
}
.base:focus {
    outline: none; 
}
.base:hover {
    background-color: var(--btn-grays-color); /* e4deb9 */
    color: #000; 
}

.content {
    position: relative;
    z-index: 2;
}

@keyframes blink-button {
    0% {
        -webkit-transform: translateX(-87px) skew(-32deg);
        transform: translateX(-87px) skew(-32deg)
    }

    50%,to {
        -webkit-transform: translateX(100%) skew(-32deg);
        transform: translateX(100%) skew(-32deg)
    }
}

.glitch {
    position: relative;
    overflow: hidden;
    opacity: 1;
}
.glitch:before {
    width: 100%;
    height: 100%;
    border-left: 48px solid #cbd2e7; /* #ff8200 */
    left: 0;
}
.glitch:after {
    width: 115%;
    height: 115%;
    border-left: 14px solid #cbd2e7; /* #ff8200 */
    left: 58px;
}

.glitch:after, .glitch:before {
    content: "";
    position: absolute;
    top: 0;
    will-change: transform;
    -webkit-animation: blink-button 3s infinite ease;
    animation: blink-button 3s infinite ease;
}
