.base {
    display: flex;
    flex-flow: column nowrap;
    box-sizing: border-box;
    height: 100%; 
}
.head {
    position: relative;
    z-index: 1;
    flex: 0 0 auto; 
}
.body {
    position: relative;
    z-index: 2;
    flex: 1 1 100%;
    -ms-flex: 1 0 auto;
}
@media all and (max-width: 1365px) {
    .body {
        margin-top: -211px; 
    } 
}
@media all and (min-width: 1408px) {
    .body {
        margin-top: -265px; 
    } 
}
@media all and (min-width: 1366px) and (max-width: 1407px) {
    .body {
        margin-top: -265px; 
    } 
}
.selectPanel {
    position: relative;
    z-index: 2;
    margin: 0 0 20px; /* 0 0 20px    20px 0 5px */
    padding: 0 30px; 
}
.categories {
    position: relative;
    z-index: 1; 
}


.Header_base {
    position: relative;
    box-sizing: border-box;
    height: 530px; /* 590px */
    background: url(/public/static/portal/images/header-bg.jpg) bottom center no-repeat/cover; 
}
@media all and (max-width: 1365px) {
    .Header_base {
        padding: 41px 0 0; } 
}
@media all and (min-width: 1366px) and (max-width: 1407px) {
    .Header_base {
        padding: 24px 0 0; } 
}
@media all and (min-width: 1408px) {
    .Header_base {
        padding: 24px 0 0; } 
}
  
.Header_inner {
    box-sizing: border-box;
    height: 100%;
    margin: 0 auto; 
}
@media all and (max-width: 1365px) {
    .Header_inner {
        width: 1122px; /* 1022px */
        padding: 0 28px; } 
}
@media all and (min-width: 1366px) and (max-width: 1407px) {
    .Header_inner {
        width: 1366px;
        padding: 0 40px; } 
}
@media all and (min-width: 1408px) {
    .Header_inner {
        width: 1408px;
        padding: 0 40px; } 
}

.Header_logo {
    display: block;
    background-repeat: no-repeat;
    background-position: center; 
}
@media all and (max-width: 1365px) { /* Splash_4.png */
    .Header_logo {
        width: 600px; /* 133px */
        height: 160px; /* 140px */
        margin: 0 auto 28px; /* 0 auto 28px   0 auto 28px 221px   0 108px 28px 108px */
        background-image: url(/public/static/portal/images/Splash_4.png); /* header-logo-small.png */
    } 
}
@media all and (min-width: 1366px) and (max-width: 1407px) {
    .Header_logo {
        width: 600px; /* 167px */
        height: 176px;
        margin: 0 auto 10px; /* 0 auto 10px  0 auto 10px 200px   0 84px 10px 84px */
        background-image: url(/public/static/portal/images/Splash_4.png); /* header-logo-large_.png */
    } 
}
@media all and (min-width: 1408px) {
    .Header_logo {
        width: 600px; /* 167px */
        height: 176px; 
        margin: 0 auto 10px; /* 0 auto 10px  0 auto 10px 200px    0 84px 10px 84px */
        background-image: url(/public/static/portal/images/Splash_4.png); /* header-logo-large_.png */
    } 
}

.searchPanel {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    box-sizing: border-box;
    /*height: 100%;*/
    margin: 0 auto; 
}
@media all and (max-width: 1365px) {
    .searchPanel {
        width: 1122px; /* 1022px 1122px */
        /*padding: 0 28px; */
    }
}
@media all and (min-width: 1366px) and (max-width: 1407px) {
    .searchPanel {
        width: 1366px;
        /*padding: 0 40px; */
    }
}
@media all and (min-width: 1408px) {
    .searchPanel {
        width: 1408px;
        /*padding: 0 40px; */
    }
}
  

@media all and (max-width: 1365px) {
    .Header_search {
        margin: 0 auto 0 0px;
    }
}
@media all and (min-width: 1366px) and (max-width: 1407px) {
    .Header_search {
        margin: 0 auto 0 0px;
    }
}
@media all and (min-width: 1408px) {
    .Header_search {
        margin: 0 auto 0 0px;
    }
}


@media all and (max-width: 1365px) {
    .searchesLogo {
        margin: 0 auto 0 0px;
    }
}
@media all and (min-width: 1366px) and (max-width: 1407px) {
    .searchesLogo {
        margin: 0 auto 0 0px;
    }
}
@media all and (min-width: 1408px) {
    .searchesLogo {
        margin: 0 auto 0 0px;
    }
}