.base {
    position: relative;
    display: inline;
    padding: 0 0 0 20px;
    color: var(--action-blue-color); /* #f9b723 #23a2f9 */
    font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    line-height: 15px; 
}
.base::before {
    position: absolute;
    top: -7px;
    left: -9px;
    width: 30px;
    height: 30px;
    background: url(/public/static/portal/images/alert.png) center no-repeat;
    content: ''; 
}
