.base {
    display: flex;
    flex-flow: column nowrap;
    box-sizing: border-box;
    height: 100%; 
}
.head {
    flex: 0 0 auto; 
}
.body {
    flex: 1 1 100%;
    -ms-flex: 1 0 auto;
}
.body a, a:hover {
    color: rgba(206, 212, 224, 1);
}
.body a:hover {
    color: rgba(206, 212, 224, 1);
}
.inner {
    padding: 3px 0 0; /* 43px 0 0 */
}
@media all and (min-width: 1408px) {
    .inner {
        width: 929px; 
    } 
}


.product_section {
    margin-top: 50px;
}

@media all and (min-width: 1366px) and (max-width: 1407px) { /*  min-width 568px */
    .product_section {
        margin-top: 60px;
    }
}

@media all and (min-width: 1408px) { /* 1025px */
    .product_section {
        margin-top: 80px;
    }
}

/* color: rgb(206, 212, 224, 1); rgba(255, 255, 233, 0.8) #b8b8a2 */

.support_panel {
    -ms-flex-line-pack: center;
    align-content: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    /*min-height: 195px;*/
    padding-bottom: 50px;
}

@media all and (min-width: 1366px) and (max-width: 1407px) { /* min 568px */
    .support_panel {
        margin-top: -15px;
    }
}

@media all and (min-width: 1408px) { /* 1025px */
    .support_panel {
        margin-top: -30px;
    }
}


.support_panel_content {
    width: 100%;
}

@media all and (min-width: 1365px) { /* 640px */
    .support_panel_content {
        width: 44.01042vw;
    }
}

@media all and (min-width: 1366px) and (max-width: 1407px) { /* min 1025px */
    .support_panel_content {
        width: 44.16667vw; /* width: 31.52778vw; */
    }
}

@media all and (min-width: 1408px) { /* 1780px */
    .support_panel_content {
        width: 786px; /* width: 561px; */
    }
}

.support_text {
    line-height: 26px;
    margin-bottom: 25px;
    margin-top: 10px;
}

.support_controls {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
}

@media all and (min-width: 1365px) { /* 640px */
    .support_controls {
        width: auto;
    }
}


.support_panel_icon {
    height: 100%;
    position: absolute;
    right: 0;
}

.support_panel_icon_wrapper {
    display: none;
    margin: -30px 0;
    position: relative;
}

@media all and (min-width: 640px) { /* 1365px */
    .support_panel_icon_wrapper {
        width: 40.5816vw;
    }
}

@media all and (min-width: 1025px) { /* (min-width: 1366px) and (max-width: 1407px) */
    .support_panel_icon_wrapper {
        width: 38.88889vw;
    }
}
@media all and (min-width: 1780px) { /* 1408px */
    .support_panel_icon_wrapper {
        width: 692px;
    }
}
@media all and (min-width: 1025px) { /* (min-width: 1366px) and (max-width: 1407px) */
    .support_panel_icon_wrapper {
        width: 29.44444vw;
    }
}
@media all and (min-width: 1780px) { /* 1408px */
    .support_panel_icon_wrapper {
        width: 524px;
    }
}
@media all and (min-width: 640px) { /* 1365px */
    .support_panel_icon_wrapper {
        display: block;
    }
}


.list_cards {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    /*margin: -1.90217vw;*/
}

/*@media all and (min-width: 568px) {
    .list_cards {
        margin:-1.95312vw;
    }
}

@media all and (min-width: 1025px) {
    .list_cards {
        margin:-1.04167vw;
    }
}

@media all and (min-width: 1780px) {
    .list_cards {
        margin:-18.5px;
    }
}*/

.list_cards_item {
    opacity: 1;
    -webkit-transition-duration: .5s;
    transition-duration: .5s;
    -webkit-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    visibility: visible;
    margin: 1.90217vw;
}

@media all and (min-width: 568px) { 
    .list_cards_item {
        margin: 1.95312vw;
    }
}

@media all and (min-width: 1366px) and (max-width: 1407px) { /* 1025px */
    .list_cards_item {
        margin: 1.04167vw;
    }
}

@media all and (min-width: 1408px) { /* 1780px */
    .list_cards_item {
        margin: 18.5px;
    }
}


.category_card {
    background: rgba(154, 173, 192, 0.15); /* 192, 174, 154 */
    -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
    display: block;
    position: relative;
    text-decoration: none;
    -webkit-transition: background 0.15s ease-in-out;
    transition: background 0.15s ease-in-out;
    height: 49.21875vw;
    width: 87.5vw;
}
@media all and (min-width: 568px) {
    .category_card {
        height: 47.31445vw;
        width: 84.11458vw;
    }
}
@media all and (min-width: 1366px) and (max-width: 1407px) { /* 1025px */
    .category_card {
        height: 48.51562vw;
        width: 86.25vw;
    }
}
@media all and (min-width: 1408px) { /* 1780px */
    .category_card {
        height: 863.4375px;
        width: 1535px;
    }
}
@media all and (min-width: 480px) {
    .category_card {
        height: 23.5394vw;
        width: 41.84783vw;
    }
}
@media all and (min-width: 568px) {
    .category_card {
        height: 22.55859vw;
        width: 40.10417vw;
    }
}
@media all and (min-width: 1366px) and (max-width: 1407px) { /* 1025px */
    .category_card {
        height: 23.67187vw;
        width: 42.08333vw;
    }
}
@media all and (min-width: 1408px) { /* 1780px */
    .category_card {
        height: 421.3125px;
        width: 749px;
    }
}
@media all and (min-width: 900px) {
    .category_card {
        height: 14.30664vw;
        width: 25.43403vw;
    }
}
@media all and (min-width: 1366px) and (max-width: 1407px) { /* 1025px */
    .category_card {
        height: 15.39062vw;
        width: 27.36111vw;
    }
}
@media all and (min-width: 1408px) { /* 1780px */
    .category_card {
        height: 273.9375px;
        width: 487px;
    }
}
@media all and (min-width: 1366px) and (max-width: 1407px) { /* 1025px */
    .category_card {
        height: 11.25vw;
        width: 20vw;
    }
}
@media all and (min-width: 1408px) { /* 1780px */
    .category_card {
        height: 200.25px;
        width: 356px;
    }
}

.category_card_mask {
    -webkit-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.category_card_name {
    bottom: 20px;
    /*font-family: "Roboto Medium", arial, helvetica, tahoma, sans-serif;*/
    font-size: 18px;
    padding-left: 30px;
    position: absolute;
    width: 50%
}

@media all and (min-width: 420px) {
    .category_card_name {
        bottom: 36px;
        font-size: 22px;
        padding-left: 40px;
    }
}
/*@media all and (min-width: 480px) {
    .category_card_name {
        bottom: 16px;
        font-size: 16px;
        padding-left: 20px;
        width: 95%;
    }
}*/
@media all and (min-width: 568px) {
    .category_card_name {
        width: 50%;
    }
}
@media all and (min-width: 700px) {
    .category_card_name {
        bottom: 21px;
        font-size: 18px;
        padding-left: 25px;
    }
}
/*@media all and (min-width: 900px) {
    .category_card_name {
        bottom: 16px;
        font-size: 16px;
        padding-left: 20px;
    }
}*/
@media all and (min-width: 1025px) {
    .category_card_name {
        width: 95%;
    }
}
@media all and (min-width: 1366px) and (max-width: 1407px) { /* 1184px */
    .category_card_name {
        width: 50%;
    }
}
@media all and (min-width: 1408px) { /* 1440px */
    .category_card_name {
        bottom: 26px;
        padding-left: 30px;
    }
}

.category_card_text {
    position: relative;
}

.category_card:active .category_card_text,.category_card:focus .category_card_text,.category_card:hover .category_card_text {
    border-bottom: 2px solid rgba(255, 255, 255, 0.7);
}

.category_card_text::after {
    content: "a";
    display: inline-block;
    font-family: "CSP Icons";
    line-height: 1;
    text-transform: none;
    bottom: 4px;
    font-size: 10px;
    position: absolute;
    right: -14px;
}

@-moz-document url-prefix() {
    .category_card_text::after {
        display: none;
    }
}


.category_card_icon_wrapper {
    -ms-flex-align: center;
    align-items: center;
    display: -ms-inline-flexbox;
    display: inline-flex;
    height: 100%;
    padding-right: 10px;
    position: absolute;
    right: 0;
    width: 45%;
}

@media all and (min-width: 480px) {
    .category_card_icon_wrapper {
        display: none;
    }
}
@media all and (min-width: 568px) {
    .category_card_icon_wrapper {
        display: -ms-inline-flexbox;
        display: inline-flex;
    }
}
@media all and (min-width: 1025px) {
    .category_card_icon_wrapper {
        display: none;
    }
}
@media all and (min-width: 1184px) {
    .category_card_icon_wrapper {
        display: -ms-inline-flexbox;
        display: inline-flex;
    }
}

.category_card_icon {
    width: 100%
}