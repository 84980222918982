.base {
    color: var(--all-text-color); /* #b8b8a2 */ 
    font-size: 16px;
    line-height: 25px;
    word-wrap: break-word; 
}
.base:not(:last-child) {
    margin-bottom: 50px; 
}
.base.isEditor ul,
.base.isEditor ol {
    margin-bottom: 25px;
    list-style: none;
    counter-reset: list; 
}
.base.isEditor ul > li,
.base.isEditor ol > li {
    position: relative;
    margin: 0; 
}
.base.isEditor ul > li:not(:last-child),
.base.isEditor ol > li:not(:last-child) {
    margin: 0 0 25px; 
}
.base.isEditor ul > li::before {
    position: absolute;
    top: 11px;
    left: 0;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: var(--iseditor-li-before-background-color);
    content: ''; 
}
.base.isEditor ol > li::before {
    display: inline-block;
    margin-right: 4px;
    color: var(--all-text-color); /* #b8b8a2 */
    font-size: 16px;
    line-height: 25px;
    vertical-align: top;
    content: counter(list) ".";
    counter-increment: list; 
}
.base p,
.base ul,
.base ol,
.base strong,
.base em,
.base u,
.base s,
.base mark,
.base figcaption {
    font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif; 
}
.base h2,
.base h3,
.base h4 {
    font-family: "Roboto Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif; 
}
.base p,
.base hr,
.base figure,
.base blockquote {
    margin-bottom: 25px;
    padding: 0; 
}
.base p:first-child,
.base hr:first-child,
.base figure:first-child,
.base blockquote:first-child {
    margin-top: 0; 
}
.base p:last-child,
.base hr:last-child,
.base figure:last-child,
.base blockquote:last-child {
    margin-bottom: 0; 
}
.base h2,
.base h3 {
    margin-bottom: 21px;
    color: var(--all-text-color); /* #e9e3be */ 
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase; 
}
.base h2:first-child,
.base h3:first-child {
    margin-top: 0; 
}
.base h2:last-child,
.base h3:last-child {
    margin-bottom: 0; 
}
.base h4 {
    margin-bottom: 15px;
    color: var(--iseditor-h4-color); /* #fefbed */ 
    font-size: 17px;
    line-height: 1; 
}
.base h4:first-child {
    margin-top: 0; 
}
.base h4:last-child {
    margin-bottom: 0; 
}
.base strong {
    color: var(--iseditor-h4-color); /* #fefbed */ 
    font-weight: 700; 
}
.base em {
    font-style: italic; 
}
.base u {
    text-decoration: underline; 
}
.base s {
    text-decoration: line-through; 
}
.base ul {
    margin-bottom: 25px;
    list-style: none;
    counter-reset: list; 
}
.base ul > li {
    position: relative;
    padding: 0 0 0 17px; 
}
.base ul > li:not(:last-child) {
    margin-bottom: 25px; 
}
.base ul > li::before {
    position: absolute;
    top: 11px;
    left: 0;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: var(--iseditor-li-before-background-color);
    content: ''; 
}
.base ul > li > ul {
    margin: 11px 0 0 15px; 
}
.base ul > li > ul > li:not(:last-child) {
    margin-bottom: 11px; 
}
.base ol {
    margin-bottom: 25px;
    list-style: none;
    counter-reset: list; 
}
.base ol > li {
    position: relative;
    margin-top: 51px;
    margin-bottom: 51px; 
}
.base ol > li::before {
    display: inline-block;
    margin-right: 4px;
    color: var(--all-text-color); /* #e9e3be */ 
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    vertical-align: top;
    content: counter(list) ".";
    counter-increment: list; 
}
.base ol > li > h2 {
    display: inline-block;
    vertical-align: top; 
}
.base mark {
    background: transparent;
    color: var(--main-blue-color); /* #fab81b */
}
.base hr {
    width: 100%;
    height: 1px;
    margin: -10px 0 23px;
    border: none;
    background-color: var(--iseditor-hr-color); /* #2b2b2c */
}
.base figure[class='media'] > div {
    overflow: hidden;
    border-radius: 10px; 
}
.base figure[class='image'] > img {
    border-radius: 10px; 
}
.base a {
    white-space: normal; 
}
.base a strong {
    color: var(--main-blue-color); /* #f9b723 */
}
.base a:hover strong {
    color: var(--link-active-color); /* #ffd428   #2882ff */
}
.base figcaption {
    margin: 10px 0 0; 
}
.base img {
    display: inline-block;
    width: 100%;
    vertical-align: top;
}
.base blockquote {
    position: relative;
    padding: 0 0 0 25px;
    border: none;
    font-style: italic; 
}
.base blockquote::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 3px;
    background-color: var(--iseditor-h4-color); /* #fefbed */ 
    content: ''; 
}

.image-style-side {
    width: 50%;
    margin: 0 0 0 25px;
    float: right; 
}
