.base {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 50px;
    padding: 0 0 0 60px;
    font-size: 11px;
    line-height: 15px;
    text-transform: uppercase; 
}

.title {
    display: inline-block;
    margin: 0 20px 8px 0;
    color: var(--main-blue-color); /* #f9b723 */
    vertical-align: top;
    cursor: pointer;
    transition: color 0.4s; 
}
.title:hover {
    color: var(--fileupload-title-hover-color); /* #ffd428 */
}

.caption {
    color: var(--fileupload-caption-color); /* 858271 */
}
.caption mark {
    background: transparent;
    color: var(--fileupload-caption-mark-color); /* f9f5e1 */
    font-weight: 700; 
}
.caption em {
    color: var(--fileupload-caption-em-color);
    font-weight: 700;
    font-style: normal; 
}

.icon {
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    border: 1px solid var(--main-blue-color); /* #f9b723 */
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.4s; 
}
.icon::before, .icon::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: center;
    content: '';
    transition: opacity 0.4s; 
}
.icon::before {
    background-image: url(/public/static/portal/images/file-upload.svg);
    opacity: 1; 
}
.icon::after {
    background-image: url(/public/static/portal/images/file-upload-hover.svg);
    opacity: 0; 
}
.title:hover .icon {
    border-color: var(--main-blue-color); /* #f9b723 */
    background-color: var(--main-blue-color); /* #f9b723 */
}
.title:hover .icon::before {
    opacity: 0; 
}
.title:hover .icon::after {
    opacity: 1; 
}
.base.isError .title:hover .icon {
    border-color: var(--main-blue-color); /* #f9b723 */
}
.base.isUploading .title:hover .icon {
    border-color: var(--fileupload-isuploading-1-color); 
}
.base.isError .icon {
    border-color: var(--fileupload-caption-em-color); 
}
.base.isUploading .icon {
    border: 3px solid var(--fileupload-isuploading-1-color);
    background: var(--fileupload-isuploading-2-color) url(/public/static/portal/images/file-upload-cancel.png) center no-repeat; 
}
.base.isUploading .icon::before, .base.isUploading .icon::after {
    content: normal; 
}
.base.isUploaded .icon::before {
    background-image: url(/public/static/portal/images/file-upload-uploaded.svg); 
}
.base.isUploaded .icon::after {
    background-image: url(/public/static/portal/images/file-upload-uploaded-hover.svg); 
}

.progressRing {
    position: absolute;
    top: -7px;
    left: -7px;
    width: 50px;
    height: 50px;
    opacity: 1;
    transition: opacity 0.4s;
    pointer-events: none; 
}
.title:hover .progressRing {
    opacity: .3; 
}

.fileName {
    display: inline-block;
    max-width: 245px;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
    white-space: nowrap; 
}

.fileSize {
    margin: 0 0 0 7px; 
}

.input {
    display: none; 
}
