.base {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); 
    -webkit-user-select: none;
    user-select: none;
}

@media all and (max-width: 1365px) {
    .base {
        right: 28px; } 
}
@media all and (min-width: 1366px) and (max-width: 1407px) {
    .base {
        right: 40px; } 
}
@media all and (min-width: 1408px) {
    .base {
        right: 40px; } 
}

.base_menu {
    position: relative;
    width: 102px;
    height: 32px;
    color: var(--btn-user-menu-base-color);
    line-height: 32px;
}
.base_menu::after {
    content: '';
    background-color: var(--btn-user-menu-after-color);
    width: 100px;
}

.granica::after {
    border-bottom: 2px solid var(--granica-after-color); 
}
.base_menu.granica::before {
    content: '';
    position: absolute;
    top: -15px;
    left: 0;
    border-left: 1px solid var(--btn-user-menu-granica-before-color);
    height: 60px;
    z-index: 102;
}
.base_menu.granica::after {
    content: '';
    position: absolute;
    top: -15px;
    right: 0;
    left: 0;
    border-right: 1px solid var(--btn-user-menu-granica-after-color);
    height: 60px;
    z-index: 101;
}

.btn_sign {
    display: inline-block;
    border: 0 none;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    background-image: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
}
.btn_btn {
    display: inline-block;
    height: 60px;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    font-weight: 700;
    background-image: none;
    height: 40px;
    padding-left: 0px;
    padding-right: 5px;
    margin-left: 5px;
}
.btn_index {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 102;
}

.login {
    color: var(--all-text-color);
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
    text-transform: uppercase;
}
.login:hover {
    color: var(--btn-user-menu-login-text-hover-color);
}

.opacity {
    filter: alpha(opacity=60);
    opacity: 0.6;
}
.opacity__up {
    filter: alpha(opacity=100);
    opacity: 1;
    color: var(--btn-user-menu-login-text-hover-color);
}

.show {
    display: block;
}