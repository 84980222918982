.Select_base {
    position: relative;
    z-index: 1;
    display: inline-block;
    width: 100%;
    height: 32px;
    vertical-align: top;
    user-select: none; 
}
.Select_base.Select_isDisabled {
    opacity: .5;
    pointer-events: none; 
}

.Select_value {
    position: relative;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 0 40px 0 20px;
    border-radius: 16px;
    background-color: rgba(5, 5, 5, 0.4);
    color: #bedde9; /* #e9e3be */
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.4s; 
}
.Select_value::after {
    position: absolute;
    top: 50%;
    right: 20px;
    width: 9px;
    height: 6px;
    background-image: url(/public/static/portal/images/select-arrow.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    content: '';
    transform: translateY(-50%); 
}
.Select_value.Select_isOpened {
    border-radius: 16px 16px 0 0; 
}
    .Select_value.Select_isOpened::after {
    background-position: 0 -6px; 
}
.Select_value.Select_isOpened, .Select_value:hover {
    background-color: #050505; 
}
.Select_base.Select_isLanguage .Select_value {
    position: relative;
    padding-left: 42px; 
}
.Select_base.Select_isLanguage .Select_value::before {
    position: absolute;
    top: 8px;
    left: 19px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 1px rgba(39, 39, 41, 0.3); 
}
.Select_base.Select_isLanguage .Select_value__ru::before {
    background: url(/public/static/portal/images/flags.png) 0 0 no-repeat;
    content: ''; 
}
.Select_base.Select_isLanguage .Select_value__en::before {
    background: url(/public/static/portal/images/flags.png) 0 -15px no-repeat;
    content: ''; 
}

.Select_dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    display: none;
    max-height: 160px;
    border-radius: 0 0 16px 16px;
    background-color: #050505; 
}
.Select_base.Select_isOpened .Select_dropdown {
    display: block; 
}

.Select_option {
    position: relative;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 32px;
    padding: 0 20px;
    border-radius: 16px;
    color: #717c85; /* #858271 */
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.4s, color 0.4s; 
}
.Select_option:hover {
    background-color: rgba(255, 255, 255, 0.06);
    color: #bedde9; /* #e9e3be */
}
.Select_base.Select_isLanguage .Select_option {
    position: relative;
    padding-left: 42px; 
}
.Select_base.Select_isLanguage .Select_option::before {
    position: absolute;
    top: 8px;
    left: 19px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 1px rgba(39, 39, 41, 0.3); 
}
.Select_base.Select_isLanguage .Select_option__ru::before {
    background: url(/public/static/portal/images/flags.png) 0 0 no-repeat;
    content: ''; 
}
.Select_base.Select_isLanguage .Select_option__en::before {
    background: url(/public/static/portal/images/flags.png) 0 -15px no-repeat;
    content: ''; 
}
