.Voting_base {
    position: relative;
    width: 302px;
    height: 215px; /* 229px */
    text-align: center; 
}

.Voting_body {
    position: relative;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    width: 214px;
    height: 214px;
    border-radius: 50%;
    vertical-align: top; 
}
.Voting_body::after {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    width: 258px;
    height: 258px;
    background-image: url(/public/static/portal/images/voting-bg.png);
    background-repeat: no-repeat;
    background-position: center;
    content: '';
    transform: translate(-50%, -50%); 
}
.Voting_base.Voting_isEmpty .Voting_body::after {
    width: 214px;
    height: 214px;
    border-radius: 50%;
    background: rgba(23, 3, 3, 0.3); 
}

.Voting_chart {
    position: absolute;
    top: 50%; /* 50% */
    left: 50%;
    z-index: 2;
    margin: 10px 0 0;
    transform: translate(-50%, -50%);
    pointer-events: none; 
}

.Voting_rating {
    position: relative;
    /* top: -6px;  */
}
/*.Voting_rating::after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 217px;
    height: 165px;
    background-image: url(/public/static/portal/images/voting-glow.png);
    content: '';
    transform: translate(-50%, -50%); }
    .Voting_base.Voting_isEmpty .Voting_rating::after {
    content: normal; 
}*/

.Voting_ratingLabel {
    color: #8790a2; /* #a29e87 */ 
    font-size: 9px; /* 11px */
    line-height: 12px; /* 15px */
    text-transform: uppercase; 
}

.Voting_ratingValue {
    position: relative;
    z-index: 3;
    color: rgba(206, 212, 224, 1); /* #fab81b */
    font-size: 42px; /* 70px */
    font-weight: 700;
    line-height: 36px; /* 1 */
}
.Voting_base.Voting_isEmpty .Voting_ratingValue {
    color: rgba(249, 245, 225, 0.1); 
}

.Voting_vote {
    position: absolute;
    right: 0;
    bottom: 23px;
    left: 0;
    z-index: 5;
    color: #e1eff9;
    font-size: 24px;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase; 
}

.Voting_footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 4;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    height: 50px;
    border-radius: 25px;
    background-color: rgba(39, 39, 41, 1); /* #050505 */
    color: #8798a2; /* #a29e87 */
    font-size: 11px;
    line-height: 15px;
    text-transform: uppercase; 
}

.Voting_button {
    position: absolute;
    top: 0;
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.4s; 
}
.Voting_button::before, .Voting_button::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    content: '';
    transition: opacity 0.4s; 
}
.Voting_button::before {
    opacity: 1; 
}
.Voting_button::after {
    opacity: 0; 
}
.Voting_button__dislike {
    left: -1px;
    border: 1px solid #db2d1b; 
}
.Voting_button__dislike::before {
    background-image: url(/public/static/portal/images/voting-dislike.png);
    opacity: 1; 
}
.Voting_button__dislike::after {
    background-image: url(/public/static/portal/images/voting-dislike-hover.png);
    opacity: 0; 
}
.Voting_button__dislike.Voting_isActive, .Voting_button__dislike:not(.Voting_isDisabled):hover {
    background-color: #db2d1b; 
}
.Voting_button__dislike.Voting_isActive::before, .Voting_button__dislike:not(.Voting_isDisabled):hover::before {
    opacity: 0; 
}
.Voting_button__dislike.Voting_isActive::after, .Voting_button__dislike:not(.Voting_isDisabled):hover::after {
    opacity: 1; 
}
.Voting_button__like {
    right: -1px;
    border: 1px solid #0051ff; /* #f9b723 */
}
.Voting_button__like::before {
    background-image: url(/public/static/portal/images/voting-slike.png);
    opacity: 1; 
}
.Voting_button__like::after {
    background-image: url(/public/static/portal/images/voting-slike-hover.png);
    opacity: 0; 
}
.Voting_button__like.Voting_isActive, .Voting_button__like:not(.Voting_isDisabled):hover {
    background-color: #0051ff; /* #f9b723 */
}
.Voting_button__like.Voting_isActive::before, .Voting_button__like:not(.Voting_isDisabled):hover::before {
    opacity: 0; 
}
.Voting_button__like.Voting_isActive::after, .Voting_button__like:not(.Voting_isDisabled):hover::after {
    opacity: 1; 
}
.Voting_button.Voting_isDisabled {
    cursor: default; 
}
