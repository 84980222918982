.base {
    padding: 4px 0 0; 
}

.caption {
    position: relative;
    margin: 0 0 25px; 
}

.cancel {
    position: absolute;
    top: 1px;
    right: 0; 
}

.row {
    display: flex;
    flex-flow: row nowrap; 
}
.row__fileUpload {
    align-items: center;
    margin-bottom: 30px; 
}
.row:not(.row__fileUpload):not(:last-child) {
    margin: 0 0 20px; 
}

.label {
    flex: 0 0 342px;
    box-sizing: border-box;
    margin: 4px 0 0;
    padding: 0 40px 0 0; 
}

.field {
    flex: 1 1 100%; 
}

.select {
    width: 275px; 
}

.fileUploadSummary {
    margin: 0 20px 0 0; 
}

.divider {
    margin: 30px 0; 
}
