.base {
    position: relative;
    height: 50px;
    padding: 0 40px;
    border: none;
    border-radius: 25px;
    background-color: var(--action-red-color);
    color: var(--black-color);
    font-family: "Roboto Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 48px;
    text-transform: uppercase;
    white-space: nowrap;
    cursor: pointer;
    transition: background-color 0.4s; 
}
.base__speakers {
    position: relative;
    padding-left: 70px; 
}
.base__speakers::before {
    position: absolute;
    top: 50%;
    left: 40px;
    width: 20px;
    height: 15px;
    margin: -8px 0 0 0;
    background: url(/public/static/portal/images/button-red-large-speaker.svg) center no-repeat;
    content: ''; 
}
.base:focus {
    outline: none; 
}
.base:hover {
    background-color: var(--action-disabled-red-color); 
}
  
.content {
    position: relative;
    z-index: 2;
}

@keyframes blink-button {
    0% {
        -webkit-transform: translateX(-87px) skew(-32deg);
        transform: translateX(-87px) skew(-32deg)
    }

    50%,to {
        -webkit-transform: translateX(100%) skew(-32deg);
        transform: translateX(100%) skew(-32deg)
    }
}

.glitch {
    position: relative;
    overflow: hidden;
    opacity: 1;
}
.glitch:before {
    width: 100%;
    height: 100%;
    border-left: 48px solid #de473b; /* #ff8200 */
    left: 0;
}
.glitch:after {
    width: 115%;
    height: 115%;
    border-left: 14px solid #de473b; /* #ff8200 */
    left: 58px;
}

.glitch:after, .glitch:before {
    content: "";
    position: absolute;
    top: 0;
    will-change: transform;
    -webkit-animation: blink-button 3s infinite ease;
    animation: blink-button 3s infinite ease;
}
