.base {
    width: 565px;
    padding: 0 0 10px; 
}

.content {
    margin: 0 0 37px;
    color: var(--changelog-gray-color); /* b8b8a2 */
    font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    line-height: 20px; 
}
.content mark {
    color: var(--attention-mark-color); /* f9f5e1 */
    font-size: 700; 
}
