.base {
    width: 565px; 
}

.modVersion {
    padding: 8px 0 0; 
}

.select {
    width: 350px;
    margin: 14px 0 18px; 
}

.hr {
    width: 100%;
    height: 1px;
    margin: 17px 0 15px;
    border: none;
    background-color: var(--iseditor-hr-color); /* #2b2b2c */
}

.attention {
    margin: 0 0 35px;
    color: var(--changelog-gray-color); /* #b8b8a2 */
    font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    line-height: 20px; 
}
.attention mark {
    background: none;
    color: var(--attention-mark-color); /* #fefbed */
}

.hidden {
    display: none; }

.clampLines {
    word-wrap: break-word; 
}
