.base {
    display: flex;
    height: 85px;
    overflow: hidden;
    border-radius: 10px;
    background: var(--black2-50c-color); /* #050505 */
}
.base:not(:last-child) {
    margin: 0 0 20px; 
}

.mod {
    display: flex;
    flex: 0 0 588px;
    overflow: hidden;
    white-space: normal; 
}
@media all and (max-width: 1365px) {
    .mod {
        flex: 0 0 450px; 
    } 
}

.cover {
    position: relative;
    flex: 0 0 151px;
    height: 85px;
    overflow: hidden;
    background: url(/public/static/portal/images/cover.jpg) center/contain no-repeat;
    cursor: pointer;
    transition: opacity 0.4s; 
}
/*.cover::after {
    position: absolute;
    top: 0;
    right: 0;
    width: 49px;
    height: 85px;
    background: linear-gradient(to left, #050505 0%, rgba(5, 5, 5, 0) 100%);
    content: ''; 
}*/
.mod:hover .cover {
    opacity: .4; 
}

.title {
    display: flex;
    flex: 0 0 437px;
    align-items: center;
    box-sizing: border-box;
    padding: 0 20px 0 27px;
    overflow: hidden;
    color: var(--all-text-color); /* #e9e3be */
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    cursor: pointer;
    transition: color 0.4s; 
}
@media all and (max-width: 1365px) {
    .title {
        flex: 0 0 299px; 
    } 
}
.mod:hover .title {
    color: var(--summary-mark-color); /* rgba(191, 211, 254, 1)  #bfd3fe  #f9b723 */ 
}

.clampLines {
    width: 100%;
    overflow: hidden;
    word-wrap: break-word; 
}

.hidden {
    display: none; 
}

.rate {
    display: flex;
    flex: 1 1 112px;
    align-items: center; 
}

.modData {
    display: flex;
    flex: 1 1 324px;
    align-items: center;
    box-sizing: border-box;
    overflow: hidden;
    color: var(--all-text-color); /* #a29e87 */
    font-size: 11px;
    text-transform: uppercase; 
}
@media all and (max-width: 1365px) {
    .modData {
        flex: 1 0 150px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 0 20px; 
    }
}

.modDataItem {
    width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; 
}
@media all and (max-width: 1365px) {
    .modDataItem {
        width: 100%;
        padding: 6px 0; 
    }
}
@media all and (min-width: 1366px) and (max-width: 1407px) {
    .modDataItem {
        margin: 0 20px 0 0; 
    }
}
@media all and (min-width: 1408px) {
    .modDataItem {
        margin: 0 20px 0 0; 
    }
}
.modDataItem mark {
    background: none;
    color: var(--summary-mark-color); /* #f9f5e1 */
    font-weight: 700; 
}
.modDataItem.isNew {
    position: relative;
    padding: 0 0 0 20px;
    color: var(--main-blue-color); /* #f9b723 */
}
@media all and (max-width: 1365px) {
    .modDataItem.isNew {
        padding: 6px 0 6px 20px; 
    }
}
.modDataItem.isNew::before {
    position: absolute;
    top: 50%;
    left: -6px;
    width: 30px;
    height: 30px;
    background: url(/public/static/portal/images/alert.png) center no-repeat; 
    content: '';
    transform: translateY(-50%); 
}
.modDataItem.isNew mark {
    color: var(--main-blue-color); /* #f9b723 */
}

.download {
    display: flex;
    flex: 1 1 200px;
    align-items: center;
    box-sizing: border-box; 
}
@media all and (max-width: 1365px) {
    .download {
        padding: 0 10px 0 0; 
    }
}

.downloadButton {
    width: 180px; 
}

.buttons {
    display: flex;
    flex: 0 0 104px;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    padding: 0 10px 0 0; 
}
@media all and (max-width: 1365px) {
    .buttons {
        flex: 0 0 94px; 
    }
}

.button {
    margin: 0 10px 0 0; 
}
