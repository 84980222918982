.base {
    box-sizing: border-box;
    padding: 13px 20px;
    border-left: 2px solid transparent;
    border-radius: 0 10px 10px 0;
    background-color: rgba(225, 239, 249, 0.04); /* rgba(249, 245, 225, 0.04) */
    color: #a2a9b8; /* #b8b8a2 */
    font-size: 15px;
    line-height: 24px; 
}
.base strong {
    color: #6d9bff; /* #e9e3be */
    font-weight: 700; 
}
.base ol {
    list-style-position: inside;
    list-style-type: decimal; 
}
.base__warning {
    border-color: #db2d1b; 
}
.base__info {
    border-color: #395eff; 
}
