.base {
  display: flex;
  flex-flow: column nowrap;
  box-sizing: border-box;
  height: 100%; 
}
.head {
  flex: 0 0 auto; 
}
.body {
  flex: 1 1 100%;
  -ms-flex: 1 0 auto;
}
.inner {
  padding: 43px 0 0; 
}
@media all and (min-width: 1408px) {
  .inner {
      width: 929px; 
  } 
}
  

.Content_base {
  color: var(--all-text-color); /* rgba(255, 255, 233, 0.8) #b8b8a2 */
  font-size: 16px;
  line-height: 25px;
  word-wrap: break-word; 
}
.Content_base:not(:last-child) {
  margin-bottom: 50px; 
}
.Content_base.Content_isEditor ul,
.Content_base.Content_isEditor ol {
  margin-bottom: 25px;
  list-style: none;
  counter-reset: list; 
}
.Content_base.Content_isEditor ul > li,
.Content_base.Content_isEditor ol > li {
  position: relative;
  margin: 0; 
}
.Content_base.Content_isEditor ul > li:not(:last-child),
.Content_base.Content_isEditor ol > li:not(:last-child) {
  margin: 0 0 25px; 
}
.Content_base.Content_isEditor ul > li::before {
  position: absolute;
  top: 11px;
  left: 0;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: var(--eula-editor-ul-color); /* #757569 */
  content: ''; 
}
.Content_base.Content_isEditor ol > li::before {
  display: inline-block;
  margin-right: 4px;
  color: var(--white-color); /* rgba(255, 255, 233, 0.8) b8b8a2 */
  font-size: 16px;
  line-height: 25px;
  vertical-align: top;
  content: counter(list) ".";
  counter-increment: list; 
}
.Content_base p,
.Content_base ul,
.Content_base ol,
.Content_base strong,
.Content_base em,
.Content_base u,
.Content_base s,
.Content_base mark,
.Content_base figcaption {
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif; 
}
.Content_base h2,
.Content_base h3,
.Content_base h4 {
  font-family: "Roboto Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif; 
}
.Content_base p,
.Content_base hr,
.Content_base figure,
.Content_base blockquote {
  margin-bottom: 25px;
  padding: 0; 
}
.Content_base p:first-child,
.Content_base hr:first-child,
.Content_base figure:first-child,
.Content_base blockquote:first-child {
  margin-top: 0; 
}
.Content_base p:last-child,
.Content_base hr:last-child,
.Content_base figure:last-child,
.Content_base blockquote:last-child {
  margin-bottom: 0; 
}
.Content_base h2,
.Content_base h3 {
  margin-bottom: 21px;
  color: var(--eula-editor-h3-color); /* #e9e3be  rgba(255, 255, 233, 1) */
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase; 
}
.Content_base h2:first-child,
.Content_base h3:first-child {
  margin-top: 0; 
}
.Content_base h2:last-child,
.Content_base h3:last-child {
  margin-bottom: 0; 
}
.Content_base h4 {
  margin-bottom: 15px;
  color: var(--eula-editor-h4-color); /* fefbed */ 
  font-size: 17px;
  line-height: 1; 
}
.Content_base h4:first-child {
  margin-top: 0; 
}
.Content_base h4:last-child {
  margin-bottom: 0; 
}
.Content_base strong {
  color: var(--eula-editor-h4-color); /* fefbed */ 
  font-weight: 700; 
}
.Content_base em {
  font-style: italic; 
}
.Content_base u {
  text-decoration: underline; 
}
.Content_base s {
  text-decoration: line-through; 
}
.Content_base ul {
  margin-bottom: 25px;
  list-style: none;
  counter-reset: list; 
}
.Content_base ul > li {
  position: relative;
  padding: 0 0 0 17px; 
}
.Content_base ul > li:not(:last-child) {
  margin-bottom: 25px; 
}
.Content_base ul > li::before {
  position: absolute;
  top: 11px;
  left: 0;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: var(--eula-editor-ul-color); /* #757569 */
  content: ''; 
}
.Content_base ul > li > ul {
  margin: 11px 0 0 15px; 
}
.Content_base ul > li > ul > li:not(:last-child) {
  margin-bottom: 11px; 
}
.Content_base ol {
  margin-bottom: 25px;
  list-style: none;
  counter-reset: list; 
}
.Content_base ol > li {
  position: relative;
  margin-top: 51px;
  margin-bottom: 51px; 
}
.Content_base ol > li::before {
  display: inline-block;
  margin-right: 4px;
  color: var(--white-color); /* #e9e3be rgba(255, 255, 233, 0.8) */
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  vertical-align: top;
  content: counter(list) ".";
  counter-increment: list; 
}
.Content_base ol > li > h2 {
  display: inline-block;
  vertical-align: top; 
}
.Content_base mark {
  background: transparent;
  color: var(--eula-editor-mark-color); /* #fab81b */
}
.Content_base hr {
  width: 100%;
  height: 1px;
  margin: -10px 0 23px;
  border: none;
  background-color: var(--iseditor-hr-color); 
}
.Content_base figure[class='media'] > div {
  overflow: hidden;
  border-radius: 10px; 
}
.Content_base figure[class='image'] > img {
  border-radius: 10px; 
}
.Content_base a {
  white-space: normal; 
  color: var(--link-active-color);
  /*color: var(--all-text-color);*/
}
.Content_base a strong {
  color: var(--link-active-color); /* #f9b723  2371f9 */
}
.Content_base a:hover strong {
  color: var(--link-hover-color); /* #ffd428  28bbff */
}
.Content_base figcaption {
  margin: 10px 0 0; 
}
.Content_base img {
  display: inline-block;
  width: 100%;
  vertical-align: top; 
}
.Content_base blockquote {
  position: relative;
  padding: 0 0 0 25px;
  border: none;
  font-style: italic; 
}
.Content_base blockquote::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 3px;
  background-color: var(--eula-editor-h4-color); /* fefbed */ 
  content: ''; 
}

.image-style-side {
  width: 50%;
  margin: 0 0 0 25px;
  float: right; 
}
