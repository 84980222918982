.base {
    position: relative;
    color: rgba(206, 212, 224, 1); /* #e9e3be */
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase; 
}
.base mark {
    background: transparent;
    color: #0051ff; /* #fab81b */
}
.base sup {
    color: #5f6e72; /* #726f5f */
    font-size: 15px;
    vertical-align: text-top; 
}