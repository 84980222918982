.base {
    display: flex;
    flex-flow: row nowrap;
    align-items: center; 
}

.label {
    flex: 0 0 342px; 
}

.field {
    flex: 1 1 100%; 
}

.fileUploadSummary {
    margin: 0 20px 0 0; 
}
