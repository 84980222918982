.base {
    position: relative;
    height: 32px;
    padding: 0 30px;
    border: 1px solid var(--main-blue-color);/* #f9b723  rgba(206, 212, 224, 1) */
    border-radius: 16px;
    background-color: transparent;
    color: var(--main-blue-color);/*#3072ff;/* #f9b723  rgba(206, 212, 224, 1) */
    font-family: "Roboto Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 11px;
    font-weight: 700;
    line-height: 30px;
    text-transform: uppercase;
    white-space: nowrap;
    cursor: pointer;
    transition: background-color 0.4s, color 0.4s; 
}
.base::before, .base::after {
    position: absolute;
    top: 50%;
    left: 30px;
    background-repeat: no-repeat;
    background-position: center;
    content: '';
    transition: opacity 0.4s;
    transform: translateY(-50%); 
}
.base::before {
    opacity: 1; 
}
.base::after {
    opacity: 0; 
}
.base:focus {
    outline: none; 
}
.base:hover {
    background-color: var(--main-blue-color);/* #f9b723  rgba(206, 212, 224, 1) */
    color: var(--white-color); /* 000 */
}
.base:hover::before {
    opacity: 0; 
}
.base:hover::after {
    opacity: 1; 
}
.base__plus {
    padding-left: 48px; 
}
.base__plus::before, .base__plus::after {
    width: 10px;
    height: 10px; 
}
.base__plus::before {
    background-image: url(/public/static/portal/images/button-yellow-outline-small-plus.svg); 
}
.base__plus::after {
    background-image: url(/public/static/portal/images/button-yellow-outline-small-plus-hover.svg); 
}

.content {
    position: relative;
    z-index: 2;
}

@keyframes blink-button {
    0% {
        -webkit-transform: translateX(-87px) skew(-32deg);
        transform: translateX(-87px) skew(-32deg)
    }

    50%,to {
        -webkit-transform: translateX(100%) skew(-32deg);
        transform: translateX(100%) skew(-32deg)
    }
}

.glitch {
    position: relative;
    overflow: hidden;
    opacity: 1;
}
.glitch:before {
    width: 100%;
    height: 100%;
    border-left: 48px solid #005fff; /* #ff8200 */
    left: 0;
}
.glitch:after {
    width: 115%;
    height: 115%;
    border-left: 14px solid #005fff; /* #ff8200 */
    left: 58px;
}

.glitch:after, .glitch:before {
    content: "";
    position: absolute;
    top: 0;
    will-change: transform;
    -webkit-animation: blink-button 3s infinite ease;
    animation: blink-button 3s infinite ease;
}
