.main {
    display: flex;
    flex-flow: row nowrap;
    /*align-items: center;*/
    justify-content: center;
    height: 100%;
    /* #1c1c1e */
    background: transparent top 0px center / 990px auto no-repeat url(/public/static/portal/images/unavailable.png);
}

.body {
    text-align: center;
    padding: 230px 16px 40px;
    text-shadow: 1px 1px 2px rgba(5, 5, 5, 0.2); /* Параметры тени */
}

.heading {
    font-size: 44px; /* 30px */
    line-height: 32px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 12px;
}

.text {
    color: rgb(206, 212, 224, 1);
    font-size: 20px; /* 18px */
    line-height: 26px;
    max-width: 530px;
    /*margin: 0 auto;*/
    margin: 0 0 34px;
}