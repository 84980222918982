.ModState_base {
    line-height: 1;
    text-transform: uppercase;
    white-space: nowrap; 
}
.ModState_base.ModState_isBlock {
    display: inline-block;
    height: 32px;
    padding: 0 20px;
    border-radius: 10px;
    color: #050505;
    font-size: 11px;
    font-weight: 700;
    line-height: 32px;
    vertical-align: top; 
}
.ModState_base__published {
    color: green; /* 5eb940 */
}
.ModState_base__published.ModState_isBlock {
    background-color: green; /* 5eb940 */
}
.ModState_base__draft {
    color: #959595; 
}
.ModState_base__draft.ModState_isBlock {
    background-color: #959595; 
}
.ModState_base__rejected {
    color: #db2d1b; 
}
.ModState_base__rejected.ModState_isBlock {
    background-color: #db2d1b; 
}
.ModState_base__review {
    color: #0051ff; /* f9b723 */
}
.ModState_base__review.ModState_isBlock {
    background-color: #0051ff; /* f9b723 */
}
.ModState_base__hidden {
    color: #fff; 
}
.ModState_base__hidden.ModState_isBlock {
    background-color: #fff; 
}
.ModState_base__published_update_in_review {
    color: #0051ff; /* f9b723 */
}
.ModState_base__published_update_in_review.ModState_isBlock {
    background-color: #0051ff; /* f9b723 */
}
.ModState_base__published_update_rejected {
    color: #db2d1b; 
}
.ModState_base__published_update_rejected.ModState_isBlock {
    background-color: #db2d1b; 
}
