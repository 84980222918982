.base {
    position: relative;
    width: 114px;
    height: 114px;
    text-align: center; 
}

.spinner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: url(/public/static/portal/images/spinner.png) center no-repeat;
    animation: spin 3s linear infinite; 
}

@keyframes spin {
    to {
        transform: rotate(360deg); 
    }
}

.logo {
    position: absolute;
    top: 6px;
    right: 0;
    bottom: 0;
    left: 0;
    background: url(/public/static/portal/images/spinner-logo.png) center no-repeat; 
}
