.base {
    display: flex;
    flex-flow: row nowrap; 
}

.label {
    flex: 0 0 342px;
    box-sizing: border-box;
    margin: 4px 0 0;
    padding: 0 80px 0 0; 
}

/* stylelint-disable */
_:-ms-lang(x), .label {
    flex: 0 0 262px; 
}

/* stylelint-enable */
.alert {
    margin: 10px 0 0; 
}

.field {
    flex: 1 1 100%; 
}

.fileUploadSummary {
    margin: 0 20px 0 0; 
}

.cover {
    position: relative;
    display: block;
    width: 302px;
    height: 170px;
    border-radius: 10px;
    background: url(/public/static/portal/images/cover.jpg) center no-repeat; 
}

.image {
    display: inline-block;
    width: 302px;
    height: 170px;
    border-radius: 10px;
    vertical-align: top; 
}

.previewRemove {
    position: absolute;
    top: -10px;
    right: -10px; 
}
