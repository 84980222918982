.base {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    width: 302px;
    height: 340px;
    border-radius: 10px;
    cursor: pointer; 
}
.base::before {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    width: 338px;
    height: 376px;
    background: url(/public/static/portal/images/add-mod-background.png) center no-repeat;
    content: '';
    transition: opacity .33s;
    transform: translate(-50%, -50%); 
}
.base:hover::before {
    background: url(/public/static/portal/images/add-mod-background-hover.png) center no-repeat; 
}
.base.isDisabled {
    opacity: .3;
    pointer-events: none; 
}

.body {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: url(/public/static/portal/images/add-mod-plus.png) center no-repeat; 
}
.body:hover {
    background: url(/public/static/portal/images/add-mod-plus-hover.png) center no-repeat; 
}
