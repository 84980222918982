/* Tag */
.base {
    position: relative;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    height: 19px;
    padding: 0 15px;
    overflow: hidden;
    border-radius: 10px;
    color: rgba(0, 0, 0, 1); /* #858271  rgba(206, 212, 224, 1) */
    font-size: 11px;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: top;
    cursor: pointer;
    transition: box-shadow 0.4s, color 0.4s; 
}
.base .hole::before {
    box-shadow: 0 0 0 2000px rgba(191, 211, 254, 0.9); /* #272729 */
}
.base:hover {
    color: rgba(0, 0, 0, 1); /* #858271 rgba(132, 189, 243, 1) */
}
.base:hover .hole::before {
    box-shadow: 0 0 0 2000px rgba(206, 212, 224, 1); /* #3a3a3c */
}
.base.isChecked {
    color: #fff; /* #0e0e0f */ 
}
.base.isChecked .hole::before {
    box-shadow: 0 0 0 2000px rgba(0, 81, 255, 0.9); 
}
.base__light {
    color: #000; /* #050505 */
}
.base__light .hole::before {
    box-shadow: 0 0 0 2000px rgba(225, 231, 250, 0.35); /* rgba(249, 245, 225, 0.35); */
}
.base__light.isChecked, .base__light:hover {
    color: #050505; 
}
.base__light.isChecked .hole::before, .base__light:hover .hole::before {
    box-shadow: 0 0 0 2000px rgba(211, 216, 244, 0.75); 
}
.base__warning {
    color: #717585; /* #858271 */
}
.base__warning .hole::before {
    box-shadow: 0 0 0 2000px rgba(249, 245, 225, 0.08); 
}
.base__warning.isChecked, .base__warning:hover {
    color: rgba(206, 212, 224, 1); 
}
.base__warning.isChecked .hole::before, .base__warning:hover .hole::before {
    box-shadow: 0 0 0 2000px #db2d1b; 
}
/* yellow */
.base__yellow {
    color: #717585; /* #858271 */
}
.base__yellow .hole::before {
    box-shadow: 0 0 0 2000px rgba(249, 245, 225, 0.08); 
}
.base__yellow.isChecked, .base__yellow:hover {
    color: rgba(206, 212, 224, 1); 
}
.base__yellow.isChecked .hole::before, .base__yellow:hover .hole::before {
    box-shadow: 0 0 0 2000px #847600; 
}
/* blue */
.base__blue {
    color: #717585; /* #858271 */
}
.base__blue .hole::before {
    box-shadow: 0 0 0 2000px rgba(249, 245, 225, 0.08); 
}
.base__blue.isChecked, .base__blue:hover {
    color: rgba(206, 212, 224, 1); 
}
.base__blue.isChecked .hole::before, .base__blue:hover .hole::before {
    box-shadow: 0 0 0 2000px #001f84; 
}
.base.isError {
    box-shadow: 0 0 0 1px #db2d1b; 
}
.base.isDisabled {
    opacity: .4;
    pointer-events: none; 
}
  
.caption {
    position: relative;
    z-index: 2; 
}

.hole {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    overflow: hidden; 
}
.hole::before {
    position: absolute;
    top: 7px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    content: '';
    transition: box-shadow 0.4s, color 0.4s; 
}
.hole__left {
    right: 50%;
    left: 0; 
}
.hole__left::before {
    left: 6px; 
}
.hole__right {
    right: 0;
    left: 50%; 
}
.hole__right::before {
    right: 6px; 
}