.base {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    padding: 11px 30px;
    border-radius: 10px; /* 25px */
    /*background-color: rgba(39, 39, 41, 0.75); /* 0.95   #050505 */
    background-image: var(--categories-background-color);
}
  
.caption {
    display: inline-block;
    margin: 0 7px 0 0;
    color: var(--categories-caption-color);
    font-size: 14px;
    font-weight: 700;
    line-height: 29px;
    text-transform: uppercase;
    vertical-align: top; 
}

.tag {
    display: inline-block;
    margin: 5px 0;
    vertical-align: top; 
}
.tag:not(:last-child) {
    margin-right: 10px; 
}
  