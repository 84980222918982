.base {
    position: relative;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 50px;
    padding: 0 60px 0 0;
    color: var(--action-blue-color); /* f9b723 */
    font-size: 11px;
    line-height: 15px;
    text-transform: uppercase;
    cursor: pointer;
    transition: color 0.4s; 
}
.base::before, .base::after {
    position: absolute;
    top: 0;
    right: 0;
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    border: 1px solid var(--action-blue-color); /* f9b723 */
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    content: '';
    transition: border-color 0.4s, background-color 0.4s; 
}
.base::before {
    background-image: url(/public/static/portal/images/action-remove-mod.png);
    opacity: 1; 
}
.base::after {
    background-image: url(/public/static/portal/images/action-remove-mod-hover.png);
    opacity: 0; 
}
.base:hover:not(.isDisabled) {
    color: var(--action-disabled-blue-color); /* ffd428 */
}
.base:hover:not(.isDisabled)::before {
    opacity: 0; 
}
.base:hover:not(.isDisabled)::after {
    opacity: 1; 
}
.base:hover:not(.isDisabled)::before, .base:hover:not(.isDisabled)::after {
    border-color: var(--action-blue-color); /* f9b723 */
    background-color: var(--action-blue-color); /* f9b723 */
}
