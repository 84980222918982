.base {
    width: 565px;
    /*margin: 60px auto 60px;*/
    margin: 70px auto 0;
    padding: 30px 55px;
    position: relative;
    z-index: 1;
    flex: 0 0 auto;
    box-shadow: none;
    border-radius: 10px; /* 2px */
    box-shadow: 0 0 500px rgb(0 10 255 / 20%);
    border: 1px rgb(0 86 255 / 30%) solid;
    background: var(--main-color); /* #161b26 */
    box-sizing: border-box
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.header_left {
    margin: 0 0 20px;
    line-height: 29px;
}

.header_left .header_left__text {
    color: #fff;
    font-size: 14px;
    margin: 0;
}

.form {
    padding: 8px 0 0; 
}

.caption {
    margin: 0 0 14px; 
}

.select {
    position: relative;
    z-index: 2; 
}

.tags {
    position: relative;
    z-index: 1;
    margin: 0 0 -10px; 
}

.tag {
    display: inline-block;
    margin: 0 10px 10px 0;
    vertical-align: top; 
}

.hr {
    width: 100%;
    height: 1px;
    margin: 17px 0 15px;
    border: none;
    background-color: var(--iseditor-hr-color); /* 171, 179, 255  #2b2b2c */
}

.fileUpload {
    padding: 5px 0 3px; 
}

.fileUploadSummary {
    margin: 0 20px 0 0; 
}

.attention {
    text-align: center;
    /*margin: 0 0 35px;*/
    margin: 0 0 auto;
    color: var(--changelog-gray-color); /* #b8b8a2 */
    font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    line-height: 20px; 
}

.fileError {
    margin: 15px 0 0; 
}

.sendError {
    margin: 0 0 35px; 
}

.input {
    /*margin: 5px 0 5px;*/
    margin-bottom: 12px;
}


.button_send {
    text-align: center;

    /*height: 44px;
    line-height: 42px;
    padding: 0 182px 0;
    border-radius: 10px;*/
}

.button_sign {
    font-size: 20px;
}

.button_sign::after {
    content: '';
    display: inline-block;
    margin-left: 4px;
    height: 15px;
    width: 14px;
    vertical-align: baseline;
    position: relative;
    right: auto;
    top: auto;
    background-image: url(/public/static/portal/images/arrow_default_lst-hover.svg);
}

.button_sign:hover::after {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(3541%) hue-rotate(121deg) brightness(98%) contrast(113%);
    /*filter: brightness(0) saturate(100%) invert(25%) sepia(35%) saturate(6804%) hue-rotate(223deg) brightness(106%) contrast(95%);*/
    /*content: '';
    background-image: url(/public/static/portal/images/arrow_default_lst.svg);*/
}


.sign_info_link {
    /*text-align: center;
    font-size: 13px;
    color: rgba(255, 255, 255, 0.54);*/
    margin-left: 4px;
    text-decoration: none;
    color: var(--main-blue-color);
}


.form_errors__block {
    height: 44px;
    background: url(/public/static/portal/images/message-error-game_wows.png);
    box-shadow: none;
    border-radius: 10px; /* 2px */
    border: none;
    margin: 0 0 10px 0; /* 0 0 30px 0 */
    color: #8f8f8e;
    font-size: 14px;
    line-height: 1.14;
    position: relative;
}

.msg_error__content {
    background: url(/public/static/portal/images/message-error-ico-dark.png) no-repeat;
    background-position: 14px 12px; /* 14px 18px */
    background-size: auto;
    border-left: none;
    padding: 14px 10px 13px 43px; /* 20px 10px 20px 43px */
}

.form_errors__block .msg_error__content p:first-child {
    margin-top: 0;
}

.form_errors__block .msg_error__content .form_errors__content {
    margin-top: 5px;
    margin-bottom: 0;
    color: rgba(255, 255, 255, 0.5);
}



.layout-airy_content {
    padding: 0;
    width: 100%;
    max-width: 554px;
    margin: 139px auto 0;
    padding: 30px 55px;
    position: relative;
    z-index: 1;
    flex: 0 0 auto;
    box-shadow: none;
    border-radius: 2px;
    /*box-shadow: 0 0 500px rgba(0,105,255,0.5);
    border: 1px rgba(0,105,255,0.3) solid;
    background: #00142D;*/
    box-shadow: 0 0 500px rgb(0 10 255 / 50%);
    border: 1px rgb(0 86 255 / 30%) solid;
    background: #161b26;
    box-sizing: border-box
}