.base {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.lines__1 {
    -webkit-line-clamp: 1;
}

.lines__2 {
    -webkit-line-clamp: 2;
}

.lines__3 {
    -webkit-line-clamp: 3;
}

.lines__4 {
    -webkit-line-clamp: 4;
}

.lines__5 {
    -webkit-line-clamp: 5;
}

.lines__6 {
    -webkit-line-clamp: 6;
}

.lines__7 {
    -webkit-line-clamp: 7;
}

.lines__8 {
    -webkit-line-clamp: 8;
}

.lines__9 {
    -webkit-line-clamp: 9;
}

.lines__10 {
    -webkit-line-clamp: 10;
}