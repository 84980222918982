.base {
    color: rgb(206,212,224,1); /* #e9e3be */
    font-size: 44px;
    font-weight: 700;
    line-height: 53px; 
}
.base mark {
    background-color: transparent;
    color: #0051ff; /* #f9b723 */
}
