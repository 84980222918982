.base {
    position: relative;
    z-index: 1;
    display: inline-block;
    width: 100%;
    height: 21px;
    vertical-align: top;
    user-select: none; 
}

.value {
    position: relative;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 0 27px 0 12px;
    border-radius: 10px;
    background-color: var(--filterSelect-value-background-color); /* #e9e3be */
    color: var(--filterSelect-value-color);
    font-size: 11px;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.4s; 
}
.value::after {
    position: absolute;
    top: 50%;
    right: 11px;
    width: 9px;
    height: 6px;
    background-image: url(/public/static/portal/images/filter-select-arrow.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    content: '';
    transform: translateY(-50%); 
}
.value.isOpened {
    border-radius: 10px 10px 0 0; 
}
.value.isOpened::after {
    background-position: 0 -6px; 
}
.value.isOpened, .value:hover {
    background-color: var(--filterSelect-value-isopened-color); 
}
.base.isLanguage .value {
    position: relative; 
}
.base.isLanguage .value::before {
    position: absolute;
    top: 3px;
    left: 4px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 1px var(--main-30c-color); 
}
.base.isLanguage .value__ru {
    padding-left: 27px; 
}
.base.isLanguage .value__ru::before {
    background: url(/public/static/portal/images/flags.png) 0 0 no-repeat;
    content: ''; 
}
.base.isLanguage .value__en {
    padding-left: 27px; 
}
.base.isLanguage .value__en::before {
    background: url(/public/static/portal/images/flags.png) 0 -15px no-repeat;
    content: ''; 
}

.dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    display: none;
    border-radius: 0 0 10px 10px;
    background-color: var(--filterSelect-dropdown-color); 
}
.base.isOpened .dropdown {
    display: block; 
}

.option {
    position: relative;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 21px;
    padding: 2px 12px 0;
    border-radius: 10px;
    color: var(--filterSelect-option-color);
    font-size: 11px;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.4s; 
}
.option:hover {
    background-color: var(--filterSelect-option-hover-color); 
}
.base.isLanguage .option {
    position: relative;
    padding-left: 27px; 
}
.base.isLanguage .option::before {
    position: absolute;
    top: 3px;
    left: 4px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 1px var(--main-30c-color); 
}
.base.isLanguage .option__ru::before {
    background: url(/public/static/portal/images/flags.png) 0 0 no-repeat;
    content: ''; 
}
.base.isLanguage .option__en::before {
    background: url(/public/static/portal/images/flags.png) 0 -15px no-repeat;
    content: ''; 
}
