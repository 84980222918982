.base {
    position: relative;
    box-sizing: border-box;
    height: 44px; 
}

.password {
    padding: 0 50px 0 20px;
}
.simple {
    padding: 0 20px;
}

.input {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    /*padding: attr(data-type-input); /* padding: 0 50px 0 20px; || padding: 0 20px; */
    border: 1px solid transparent;
    border-radius: 10px;
    background-color: rgba(5, 5, 5, 0.4);
    color: rgba(190, 216, 233, 0.6); /* e9e3be */
    font-family: "Roboto Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    transition: background-color 0.4s, border-color 0.4s; 
}
.input:hover, .input:focus {
    outline: none;
    color: rgba(190, 216, 233, 1);
    background-color: #050505; /* #050505 rgba(39, 39, 41, 0.95) 1c1c1c */ 
}
.base.isError .input {
    border-color: #db2d1b;
    background-color: #050505; /* #050505 rgba(39, 39, 41, 0.95) 1c1c1c */
}

.checkPass {
    opacity: 0.7;
    /* margin: 7px 0 0; */
    font-size: 9px;
    /* font-family: 'Roboto'; */
    text-transform: uppercase;
    line-height: inherit;
    position: absolute;
    top: 18px;
    left: 408px;
}
.checkPass__red {
    color: var(--red-color);
}
.checkPass__green {
    color: var(--lightgreen-color);
}
.checkPass__yellow {
    color: var(--lightyellow-color);
}

.placeholder {
    position: absolute;
    top: 0;
    right: 20px;
    bottom: 0;
    left: 20px;
    z-index: 2;
    display: none;
    padding: 1px;
    color: #717885; /* 858271 */
    font-size: 14px;
    line-height: 44px;
    pointer-events: none; 
}
.placeholder.isVisible {
    display: block; 
}
