.ModderItem_inner {
    position: relative;
}

@media all and (max-width: 1365px) {
    .ModderItem_inner {
        margin: 0 0 30px; 
    }
}

@media all and (min-width: 1366px) and (max-width: 1407px) {
    .ModderItem_inner {
        margin: 0 0 30px; 
    }
}

@media all and (min-width: 1408px) {
    .ModderItem_inner {
        margin: 0 0 30px; 
    }
}


.ModderItem_base {
    display: flex;
    height: 85px;
    overflow: hidden;
    border-radius: 10px;
    background: rgba(5, 5, 5, 0.5); /* #050505 */
}
.ModderItem_base:not(:last-child) {
    margin: 0 0 30px; /* 20px */
}

.ModderItem_statusMod {
    position: absolute;
    top: -16px;
    left: -8px;
    z-index: 2;
}

.ModderItem_mod {
    display: flex;
    flex: 0 0 588px;
    overflow: hidden;
    white-space: normal; 
}
@media all and (max-width: 1365px) {
    .ModderItem_mod {
        flex: 0 0 450px; 
    } 
}

.ModderItem_cover {
    position: relative;
    flex: 0 0 151px;
    height: 85px;
    overflow: hidden;
    background: url(/public/static/portal/images/cover.jpg) center/contain no-repeat;
    cursor: pointer;
    transition: opacity 0.4s; 
}
/*.ModderItem_cover::after {
    position: absolute;
    top: 0;
    right: 0;
    width: 49px;
    height: 85px;
    background: linear-gradient(to left, #050505 0%, rgba(5, 5, 5, 0) 100%);
    content: ''; 
}*/
.ModderItem_mod:hover .ModderItem_cover {
    opacity: .4; 
}

.ModderItem_posAbsolute {
    position: absolute;
}


.ModderItem_field {
    /*flex: 1 1 100%; 
    align-content: center; */
    display: flex;
    flex: 1 1 230px;
    align-items: center;
    box-sizing: border-box;
    overflow: hidden;
    color: rgba(206, 212, 224, 1);
    text-transform: uppercase;
}

.ModderItem_select {
    width: 230px; 
}


.ModderItem_title {
    display: flex;
    flex: 0 0 437px;
    align-items: center;
    box-sizing: border-box;
    padding: 0 20px 0 27px;
    overflow: hidden;
    color: rgba(206, 212, 224, 1); /* #e9e3be */
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    cursor: pointer;
    transition: color 0.4s; 
}
@media all and (max-width: 1365px) {
    .ModderItem_title {
        flex: 0 0 299px; 
    } 
}
.ModderItem_mod:hover .ModderItem_title {
    color: rgba(171, 179, 255, 1); /* rgba(191, 211, 254, 1)  #bfd3fe  #f9b723 */ 
}

.ModderItem_clampLines {
    width: 100%;
    overflow: hidden;
    word-wrap: break-word; 
}

.ModderItem_hidden {
    display: none; 
}

.ModderItem_rate {
    display: flex;
    flex: 1 1 112px;
    align-items: center; 
}

.ModderItem_modData {
    display: flex;
    flex: 1 1 324px;
    align-items: center;
    box-sizing: border-box;
    overflow: hidden;
    color: rgba(206, 212, 224, 1); /* #a29e87 */
    font-size: 11px;
    text-transform: uppercase; 
}
@media all and (max-width: 1365px) {
    .ModderItem_modData {
        flex: 1 0 150px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 0 20px; 
    }
}

.ModderItem_modDataItem {
    width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; 
}
@media all and (max-width: 1365px) {
    .ModderItem_modDataItem {
        width: 100%;
        padding: 6px 0; 
    }
}
@media all and (min-width: 1366px) and (max-width: 1407px) {
    .ModderItem_modDataItem {
        margin: 0 20px 0 0; 
    }
}
@media all and (min-width: 1408px) {
    .ModderItem_modDataItem {
        margin: 0 20px 0 0; 
    }
}
.ModderItem_modDataItem mark {
    background: none;
    color: rgba(171, 179, 255, 1); /* #f9f5e1 */
    font-weight: 700; 
}
.ModderItem_modDataItem.ModderItem_isNew {
    position: relative;
    padding: 0 0 0 20px;
    color: #0051ff; /* #f9b723 */
}
@media all and (max-width: 1365px) {
    .ModderItem_modDataItem.ModderItem_isNew {
        padding: 6px 0 6px 20px; 
    }
}
.ModderItem_modDataItem.ModderItem_isNew::before {
    position: absolute;
    top: 50%;
    left: -6px;
    width: 30px;
    height: 30px;
    background: url(/public/static/portal/images/alert.png) center no-repeat; 
    content: '';
    transform: translateY(-50%); 
}
.ModderItem_modDataItem.ModderItem_isNew mark {
    color: #0051ff; /* #f9b723 */
}

.ModderItem_download {
    display: flex;
    flex: 1 1 200px;
    align-items: center;
    box-sizing: border-box; 
}
@media all and (max-width: 1365px) {
    .ModderItem_download {
        padding: 0 10px 0 0; 
    }
}

.ModderItem_downloadButton {
    width: 180px; 
}

.ModderItem_buttons {
    display: flex;
    flex: 0 0 104px;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    padding: 0 10px 0 0; 
}
@media all and (max-width: 1365px) {
    .ModderItem_buttons {
        flex: 0 0 94px; 
    }
}

.ModderItem_button {
    margin: 0 10px 0 0; 
}
