@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400,400i,500,500i,700,700i|Roboto:400,400i,500,500i,700,700i&subset=latin,cyrillic);

/* stylelint-disable selector-list-comma-newline-after  */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
  font-size: 100%;
  vertical-align: baseline; }

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
  font-style: normal;
  font-stretch: normal; }

article,
aside,
audio,
canvas,
datalist,
details,
embed,
figcaption,
figure,
footer,
header,
keygen,
menu,
nav,
output,
progress,
section,
source,
video {
  display: block;
  margin: 0; }

body {
  line-height: 1; }

ol,
ul {
  list-style-type: none; }

blockquote, q {
  quotes: none; }

blockquote::before, blockquote::after,
q::before, q::after {
  content: normal; }

/* remember to define focus styles! */
:focus {
  outline: 0; }

del {
  text-decoration: line-through; }

/* tables still need cellspacing='0' in the markup */
table {
  border-spacing: 0;
  border-collapse: collapse; }

td {
  vertical-align: top; }

button::-moz-focus-inner,
input[type='reset']::-moz-focus-inner,
input[type='button']::-moz-focus-inner,
input[type='submit']::-moz-focus-inner,
input[type='file'] > input[type='button']::-moz-focus-inner {
  border: none; }

input,
textarea,
button {
  font-family: inherit; }

button {
  margin: 0; }

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #8b8980;
  font-size: 12px;
  text-transform: uppercase; }

input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #8b8980;
  font-size: 12px;
  text-transform: uppercase; }

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #8b8980;
  font-size: 12px;
  text-transform: uppercase; }

::-ms-clear {
  display: none; }

input:-webkit-autofill {
  color: #000 !important; }

html {
  height: 100%;
  color: #fff; }

body {
  height: 100%;
  /* background-color: rgb(40, 46, 60); */
  /* background-image: url(http://localhost:3000/static/media/portal_background_3.67571d9….jpg); */
  /* background-image: url(/public/static/portal/images/sparks.png); */
  /* background-repeat: repeat-y; */
  /* background-position: top center; */
  font-family: "Roboto Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background: url(/public/images/form_bg.jpg) center center repeat-y; /* no-repeat */
  background-size: cover;
}

@media all and (max-width: 1365px) {
  html,
  body {
    min-width: 1122px; 
} /* 1022px */
}

@media all and (min-width: 1366px) and (max-width: 1407px) {
  html,
  body {
    min-width: 1366px; } 
}

@media all and (min-width: 1408px) {
  html,
  body {
    min-width: 1408px; } 
}

a {
    color: #0051ff;
    text-decoration: none;
    white-space: nowrap;
    transition: color 0.4s; 
}
a:hover {
    color: #3978ff; 
}

#root {
  height: 100%; 
}

.scroll-off {
  overflow-y: hidden;
}

@font-face {
  font-display: block;
  font-family: "CSP Icons";
  font-style: normal;
  font-weight: 400;
  src: url(/src/assets/fonts/csp-icons/csp-icons.woff) format("woff"),url(/src/assets/fonts/csp-icons/csp-icons.ttf) format("truetype"),url(/src/assets/fonts/csp-icons/csp-icons.svg#csp-icons) format("svg")
}