.base {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    visibility: hidden; 
}
.base.isOpened {
    visibility: visible; 
}

.overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: var(--black-black-85c-color);
    opacity: 0; 
}
.base.isOpened .overlay {
    opacity: 1; 
}

.dialog {
    position: relative;
    z-index: 2;
    box-sizing: border-box;
    padding: 35px 40px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 6px 50px 0 var(--black-black-75c-color);
    background: var(--black3-color); 
}

.close {
    position: absolute;
    top: 7px;
    right: 11px;
    width: 30px;
    height: 30px;
    background: url(/public/static/portal/images/dialog-close.png) center no-repeat;
    opacity: .5;
    cursor: pointer;
    transition: opacity 0.4s; 
}
.close:hover {
    opacity: 1; 
}
