.base {
    width: 565px; 
}

.content {
    margin: 0 0 35px;
    color: var(--changelog-gray-color); /* b8b8a2 */
    font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    line-height: 20px; 
}

.button {
    display: inline-block;
    vertical-align: top; 
}
.button:not(:last-child) {
    margin: 0 20px 0 0; 
}
