.ModDetails_base {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 50px;
    border-radius: 25px;
    background-color: rgba(39, 39, 41, 1); /* #050505 */
}

.ModDetails_labels {
    display: flex;
    margin: 0 0 0 28px; 
}
@media all and (max-width: 1365px) {
    .ModDetails_labels {
        flex-flow: row wrap;
        width: 45%; 
    }
}
@media all and (min-width: 1366px) and (max-width: 1407px) {
    .ModDetails_labels {
        flex-flow: row nowrap; 
    }
}
@media all and (min-width: 1408px) {
    .ModDetails_labels {
        flex-flow: row nowrap; 
    }
}

.ModDetails_label {
    margin: 1px 0 0;
    color: #8798a2; /* #a29e87 */
    font-size: 11px;
    line-height: 15px;
    text-transform: uppercase;
    white-space: nowrap; 
}
.ModDetails_label mark {
    background: transparent;
    color: #e1f2f9; /* #f9f5e1 */
    font-weight: 700; 
}
.ModDetails_label i {
    color: #db2d1b;
    font-weight: 700;
    font-style: normal; 
}
@media all and (max-width: 1365px) {
    .ModDetails_label {
        flex: 0 0 50%;
        max-width: 120px;
        overflow: hidden;
        text-overflow: ellipsis; 
    }
}
@media all and (min-width: 1366px) and (max-width: 1407px) {
    .ModDetails_label:not(:last-child) {
        margin-right: 28px; 
    }
}
@media all and (min-width: 1408px) {
    .ModDetails_label:not(:last-child) {
        margin-right: 28px; 
    }
}

.ModDetails_status {
    position: absolute;
    top: 13px;
    right: 30px;
    color: #5eb940;
    font-size: 11px;
    line-height: 25px;
    text-transform: uppercase; 
}
.ModDetails_status::before {
    position: absolute;
    top: 1px;
    left: -25px;
    width: 20px;
    height: 20px;
    background: url(/public/static/portal/images/details-status.png) center no-repeat;
    content: ''; 
}

.ModDetails_hidden {
    display: none; 
}
