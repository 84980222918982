.base {
    display: flex;
    flex-flow: column nowrap;
    box-sizing: border-box;
    height: 100%; 
}

.head {
    position: relative;
    z-index: 2;
    flex: 0 0 auto; 
}

.body {
    position: relative;
    z-index: 1;
    flex: 1 1 100%;
    /* stylelint-disable order/properties-order */
    -ms-flex: 1 0 auto;
    /* stylelint-enable */ 
}

.filter {
    position: relative;
    padding: 48px 0 35px; 
}

.filterAdditional {
    position: absolute;
    top: 48px;
    right: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start; 
}

.toggle {
    display: inline-block;
    margin: 0 30px 0 0;
    vertical-align: top; 
}

.section {
    position: relative;
    height: 100%; 
}

.inner:not(:last-child) {
    margin-bottom: -10px; 
}

.inner:last-child {
    margin-bottom: -50px; 
}

@media all and (max-width: 1365px) {
    .inner {
        margin-right: -15px;
        margin-left: -15px; 
    }
}

@media all and (min-width: 1366px) and (max-width: 1407px) {
    .inner {
        margin-right: -13px;
        margin-left: -13px; 
    }
}

@media all and (min-width: 1408px) {
    .inner {
        margin-right: -20px;
        margin-left: -20px; 
    }
}

.mod {
    display: inline-block;
    vertical-align: top; 
}
@media all and (max-width: 1365px) {
    .mod {
        margin: 0 15px 50px; 
    }
}
@media all and (min-width: 1366px) and (max-width: 1407px) {
    .mod {
        margin: 0 13px 50px; 
    }
}
@media all and (min-width: 1408px) {
    .mod {
        margin: 0 20px 50px; 
    } 
}

.button {
    text-align: center; 
}

.muted {
    color: #434343; 
}


.PaddingTop_48px {
    padding-top: 48px;
  }
.PaddingBottom_48px {
    padding-bottom: 48px;
}