.base {
    position: relative; 
}
@media all and (max-width: 1365px) {
    .base:not(:last-child) {
        margin: 0 0 35px; 
    }
}
@media all and (min-width: 1366px) and (max-width: 1407px) {
    .base:not(:last-child) {
        margin: 0 0 55px; 
    }
}
@media all and (min-width: 1408px) {
    .base:not(:last-child) {
        margin: 0 0 55px; 
    }
}

.name {
    margin: 0 0 14px;
    color: var(--attention-mark-color); /* fefbed */
    font-size: 16px;
    font-weight: 700;
    line-height: 22px; 
}

.inner {
    position: relative;
    padding: 15px 0;
    border-radius: 10px;
    background-color: var(--edit-comments-item-inner-color); 
}
.inner::before {
    position: absolute;
    top: -10px;
    left: 21px;
    width: 14px;
    height: 10px;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAKCAYAAACE2W/HAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MkQxODc0RjlGRDYyMTFFOEE0RTBDQzA0NjY4NjRDNDIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MkQxODc0RkFGRDYyMTFFOEE0RTBDQzA0NjY4NjRDNDIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoyRDE4NzRGN0ZENjIxMUU4QTRFMENDMDQ2Njg2NEM0MiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoyRDE4NzRGOEZENjIxMUU4QTRFMENDMDQ2Njg2NEM0MiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuPZT6oAAABTSURBVHjaYvz59SEDDsAMpf9ik2TCoYkRiNmgmJEUjTANMAOI0siM5ExsfKwacdmA4WQmQgpwGchEyEm45JkIOBGnk5kIOBGnk5mIcCJWJwMEGADjTARbz/cXTwAAAABJRU5ErkJggg==");
    content: ''; 
}
.base.isApproved .inner {
    border-radius: 0 10px 10px 0; 
}
.base.isApproved .inner::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 2px;
    background-color: var(--edit-comments-item-approved-color);
    content: ''; 
}
.base.isRejected .inner {
    border-radius: 0 10px 10px 0; 
}
.base.isRejected .inner::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 2px;
    background-color: var(--action-red-color);
    content: ''; 
}

.message {
    padding: 0 20px;
    color: var(--changelog-gray-color); /* b8b8a2 */
    font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 20px;
    word-wrap: break-word; 
}

.summary {
    position: absolute;
    right: 0;
    color: var(--edit-comments-item-summary-color); /* 726f5f */
    font-size: 12px;
    line-height: 22px; 
}
@media all and (max-width: 1365px) {
    .summary {
        top: 1px; 
    }
}
@media all and (min-width: 1366px) and (max-width: 1407px) {
    .summary {
        bottom: 100%;
        margin-bottom: -21px; 
    }
}
@media all and (min-width: 1408px) {
    .summary {
        bottom: 100%;
        margin-bottom: -23px; 
    }
}
.summary mark {
    background: none;
    color: var(--attention-mark-color); /* e9e3be    bec7e9 */
}

@media all and (max-width: 1365px) {
    .date {
        display: inline-block;
        margin: 0 0 0 18px;
        vertical-align: top;
    }
}

@media all and (min-width: 1366px) and (max-width: 1407px) {
    .date {
        display: block;
        text-align: right; 
    }
}

@media all and (min-width: 1408px) {
    .date {
        display: block;
        text-align: right; 
    }
}
