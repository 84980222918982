/*.ScrollToTop_base {
    position: fixed;
    right: 40px;
    bottom: 116px;
    z-index: 994;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #9bbbff; // #9bbbff //
    background-image: url(/public/static/portal/images/scroll-to-top.png);
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0;
    cursor: pointer;
    transition: background-color 0.4s, opacity 0.4s;
}
.ScrollToTop_base:hover {
	opacity: 1;
	background-color: #0051ff;
}
.ScrollToTop_base__hide {
	// display: none; //
    opacity: 0.4;
}*/

.base {
    position: fixed;
    right: 40px;
    bottom: 116px;
    z-index: 994;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgb(206, 212, 224); /* 206, 212, 224   #9bbbff   #bfd3fe */
    background-image: url(/public/static/portal/images/scroll-to-top.png);
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0;
    /*cursor: pointer;*/
    transition: background-color 0.4s, opacity 0.4s; 
}
.base:hover {
    background-color: #bfd3fe; /* #bfd3fe  #0051ff  */
}
.base.isVisible {
    opacity: 1; 
    cursor: pointer;
}
  