.base {
    margin: 0 0 58px;
    width: 550px;
}

.player__title {
    display: flex;
    justify-content: center;
}

.__player {
    background-color: rgb(216 230 255 / 15%); /* rgba(56, 61, 193, 0.15); */
    color: rgba(206, 212, 224, 1);
}