.Information_base {
    display: flex;
    flex-flow: row nowrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%; 
}
  
.Information_inner {
    text-align: center; 
}
  
.Information_message {
    margin: 0 0 37px;
    color: rgba(206, 212, 224, 1); /*color: #e9e3be;*/
    font-size: 20px;
    line-height: 1; 
}
  