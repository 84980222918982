.base {
    position: relative; 
}

.reactBlur {
    height: 294px;
    overflow: hidden; 
}

.cover {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: -1;
    height: 294px; 
}
.cover::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(28, 28, 30, 0.6);
    content: ''; 
}

.voting {
    position: absolute;
    right: 400px;
}
@media all and (max-width: 1365px) {
    .voting {
        top: 20px; /* 20px 104px */
        right: 0px;
    }
}
@media all and (min-width: 1366px) and (max-width: 1407px) {
    .voting {
        top: -10px; /* -10px 74px */
        right: 350px;
    }
}
@media all and (min-width: 1408px) {
    .voting {
        top: -10px; /* -10px 74px */
    }
}

.header {
    position: relative; 
}
@media all and (max-width: 1365px) {
    .header {
        width: 635px;
        height: 249px; 
    }
}
@media all and (min-width: 1366px) and (max-width: 1407px) {
    .header {
        width: 929px;
        height: 219px; 
    }
}
@media all and (min-width: 1408px) {
    .header {
        width: 929px;
        height: 219px; 
    }
}

.clampLines {
    word-wrap: break-word; 
}

.details {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0; 
}

.modDetails {
    margin-bottom: 50px;
}

@media all and (max-width: 1365px) {
    .summary {
        margin: 0 0 24px; 
    }
}

@media all and (min-width: 1366px) and (max-width: 1407px) {
    .summary {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin: 0 0 29px; 
    }
}

@media all and (min-width: 1408px) {
    .summary {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin: 0 0 29px; 
    }
}

.tags {
    display: flex;
    flex-flow: row nowrap;
    align-items: center; 
}
@media all and (max-width: 1365px) {
    .tags {
        margin: 0 0 14px; 
    }
}

.tag {
    display: inline-block;
    margin: 0 30px 0 0;
    vertical-align: top;
    white-space: nowrap; 
}
.tag:not(:last-child) {
    margin-right: 8px; 
}

.author {
    display: inline-block;
    max-width: 200px;
    margin: 0 20px 0 0;
    overflow: hidden;
    color: #a2a3b8; /* #b8b8a2 */
    font-size: 15px;
    line-height: 21px;
    text-overflow: ellipsis;
    vertical-align: top;
    white-space: nowrap; 
}

.owner {
    display: inline-block;
    max-width: 200px;
    overflow: hidden;
    color: #1b96fa; /* #fab81b */ 
    font-size: 15px;
    line-height: 21px;
    text-overflow: ellipsis;
    vertical-align: top;
    white-space: nowrap;
    cursor: pointer; 
}

@media all and (max-width: 1365px) {
    .body {
        width: 100%; 
    }
}

@media all and (min-width: 1366px) and (max-width: 1407px) {
    .body {
        width: 929px; 
    }
}

@media all and (min-width: 1408px) {
    .body {
        width: 929px; 
    }
}

.actions {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin: 0 0 40px;
    padding: 20px 0;
    border-bottom: 1px solid #d2d2ee; /* #2b2b2c */ 
}

.action:not(:last-child) {
    margin-right: 30px; 
}

.actions__disabled .action {
    margin-right: 10px; 
}

.actionsMessage {
    color: rgba(206, 212, 224, 1); /* #e9e3be */
    font-size: 16px;
    line-height: 1; 
}

.title {
    position: relative;
    margin: 0 0 34px; 
}

.changelogs {
    word-wrap: break-word; 
}

.descriptionLanguageSelect {
    position: absolute;
    top: -2px;
    right: 0; 
}

.loadMore {
    margin: 0 0 50px;
    text-align: center; 
}

.commentsTitle {
    position: relative;
    z-index: 2;
    height: 18px; 
}

.commentsTitleText {
    color: rgba(206, 212, 224, 1); /* #e9e3be */
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase; 
}

.commentsTitleLanguageSelect {
    position: absolute;
    top: -2px;
    right: 0;
    z-index: 2; 
}

.commentsMessage {
    position: relative;
    z-index: 2;
    padding: 37px 0 38px;
    border-bottom: 1px solid #d2d2ee; /* #2b2b2c */
    color: rgba(206, 212, 224, 1); /* #e9e3be */
    font-size: 20px; 
}

.commentsInfo {
    position: relative;
    z-index: 2;
    margin: 22px 0 34px;
    color: #a2a8b8; /* #b8b8a2 */
    font-size: 14px;
    line-height: 20px; 
}

.comments {
    min-height: 200px; 
}
.comments__en .hc__menu__item.hc__menu__comment.hc_unclick .hc__menu__count {
    left: 100px; 
}
.comments__ru--puDKB .hc__menu__item.hc__menu__comment.hc_unclick .hc__menu__count {
    left: 130px; 
}
