.ModDetailsFixedBar_base {
    position: fixed;
    /* top: -50px; */
    right: 0;
    left: 0;
    z-index: 995;
    height: 50px;
    background-color: rgba(39, 39, 41, 1); /* #050505 */
    opacity: 0;
    transition: top 0.4s, opacity 0.4s; 
}
.ModDetailsFixedBar_base.ModDetailsFixedBar_isVisible {
    bottom: 0; /* top: 0; */
    opacity: 1; 
}

.ModDetailsFixedBar_inner {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    height: 100%;
    margin: 0 auto; 
}
@media all and (max-width: 1365px) {
    .ModDetailsFixedBar_inner {
        width: 1022px;
        padding: 0 28px; 
    }
}
@media all and (min-width: 1366px) and (max-width: 1407px) {
    .ModDetailsFixedBar_inner {
        width: 1366px;
        padding: 0 40px; 
    }
}
@media all and (min-width: 1408px) {
    .ModDetailsFixedBar_inner {
        width: 1408px;
        padding: 0 40px; 
    }
}

.ModDetailsFixedBar_name {
    position: relative;
    top: -2px;
    margin: 0 40px 0 0;
    overflow: hidden;
    color: rgba(206, 212, 224, 1); /* #e9e3be */
    font-size: 18px;
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap; 
}

/* stylelint-disable */
_:-ms-lang(x), .ModDetailsFixedBar_name {
    max-width: 50%; 
}
@media all and (max-width: 1365px) {
    _:-ms-lang(x), .ModDetailsFixedBar_name {
        max-width: 40%; 
    }
}

/* stylelint-enable */
.ModDetailsFixedBar_details {
    display: flex;
    flex-flow: row nowrap;
    align-items: center; 
}

.ModDetailsFixedBar_label {
    color: #8796a2;
    font-size: 11px;
    line-height: 15px;
    text-transform: uppercase;
    white-space: nowrap; 
}
.ModDetailsFixedBar_label:not(:last-child) {
    margin-right: 28px; 
}
.ModDetailsFixedBar_label mark {
    background: transparent;
    color: #e1e9f9; /* #f9f5e1 */
    font-weight: 700; 
}
.ModDetailsFixedBar_label i {
    color: #db2d1b;
    font-weight: 700;
    font-style: normal; 
}
