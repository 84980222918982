.body {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    height: 100%;
    /*background-image: url(/public/static/portal/images/error404_wot_3.png);
    background-size: auto;
    background-position-x: center;
    background-repeat: no-repeat;*/
}

.block {
    text-align: center; 
    text-shadow: 1px 1px 2px var(--black2-20c-color); /* Параметры тени */
}
.error {
    width: 376px;
    height: 157px;
    margin: 0 0 50px;
    /*background: url(/public/static/portal/images/error404_wot_3.png) center no-repeat; */
}
.title {
    margin: 65px 0 15px;
    color: var(--all-text-color); /* #fffee5 rgb(206,212,224,1) */
    font-size: 22px; /* 44px */
    font-weight: 700;
    line-height: 1; 
}
.content {
    margin: 0 0 34px;
    color: var(--all-text-color); /* #fffee5 rgb(206,212,224,1) */
    font-size: 16px; /* 20px */
    line-height: 16px; /* 20px */
}