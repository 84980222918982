.base {
    display: flex;
    flex-flow: row nowrap; 
}
  
.label {
    flex: 0 0 342px;
    margin: 4px 0 0; 
}
  
.field {
    flex: 1 1 100%; 
}
  
.select {
    width: 275px; 
}
  