.base {
    display: flex;
    flex-flow: row nowrap; 
}
  
.label {
    flex: 0 0 342px;
    box-sizing: border-box;
    margin: 4px 0 0;
    padding: 0 40px 0 0; 
}
  
  /* stylelint-disable */
_:-ms-lang(x), .label {
    flex: 0 0 302px; 
}
  
  /* stylelint-enable */
.field {
    flex: 1 1 100%; 
}
  