.base {
    box-sizing: border-box;
    height: 100%;
    margin: 0 auto; 
}
@media all and (max-width: 1365px) {
    .base {
        width: 1122px; /* 1022px */
        padding: 0 28px 50px; 
    } 
}
@media all and (min-width: 1366px) and (max-width: 1407px) {
    .base {
        width: 1366px;
        padding: 30px 40px 50px; 
    } 
}
@media all and (min-width: 1408px) {
    .base {
        width: 1408px; /* 1408px */
        padding: 30px 40px 50px; 
    } 
}