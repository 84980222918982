.base {
    position: relative;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 50px;
    padding: 10px 0 10px 60px;
    margin-bottom: 25px;
    color: var(--all-text-color); /* 0, 81, 255   206, 212, 224 */
    font-size: 11px;
    line-height: 15px;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
    /*cursor: pointer;*/
    cursor: pointer;
    transition: color 0.4s; 
    -webkit-user-select: none;
    user-select: none;
}
.base::before, .base::after {
    position: absolute;
    top: 50%;
    left: 0;
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    margin: -25px 0 0;
    border: 1px solid var(--all-text-20c-color); /*  206, 212, 224  228, 222, 185   0, 81, 255 */ /* e4deb9 0051ff */
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    content: '';
    transition: border-color 0.4s, background-color 0.4s; 
}
.base::before {
    background-image: url(/public/static/portal/images/back.png);
    opacity: 1; 
}
.base::after {
    background-image: url(/public/static/portal/images/back-hover.png);
    opacity: 0; 
}
.base:hover:not(.isDisabled) {
    color: var(--link-hover-color);  /* #0051ff  #bfd3fe  */
}
.base:hover:not(.isDisabled)::before {
    opacity: 0; 
}
.base:hover:not(.isDisabled)::after {
    opacity: 1; 
}
.base:hover:not(.isDisabled)::before, .base:hover:not(.isDisabled)::after {
    border: 1px solid var(--link-hover-color); /* #0051ff  #bfd3fe  */
    background-color: var(--link-hover-color); /* #0051ff  #bfd3fe  */
} 

.content {
    position: relative;
    z-index: 2;
}

@keyframes blink-button {
    0% {
        -webkit-transform: translateX(-87px) skew(-32deg);
        transform: translateX(-87px) skew(-32deg)
    }

    50%,to {
        -webkit-transform: translateX(100%) skew(-32deg);
        transform: translateX(100%) skew(-32deg)
    }
}

.glitch {
    position: relative;
    overflow: hidden;
    opacity: 1;
}
.glitch:before {
    width: 100%;
    height: 100%;
    border-left: 48px solid #cbd2e7; /* #ff8200 */
    left: 0;
}
.glitch:after {
    width: 115%;
    height: 115%;
    border-left: 14px solid #cbd2e7; /* #ff8200 */
    left: 58px;
}

.glitch:after, .glitch:before {
    content: "";
    position: absolute;
    top: 0;
    will-change: transform;
    -webkit-animation: blink-button 3s infinite ease;
    animation: blink-button 3s infinite ease;
}
