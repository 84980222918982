.base {
    width: 565px; 
}

.base span {
    color: var(--all-text-color);
}

.form {
    padding: 8px 0 0; 
}

.caption {
    margin: 0 0 14px; 
}

.select {
    position: relative;
    z-index: 2; 
}

.tags {
    position: relative;
    z-index: 1;
    margin: 0 0 -10px; 
}

.tag {
    display: inline-block;
    margin: 0 10px 10px 0;
    vertical-align: top; 
}

.hr {
    width: 100%;
    height: 1px;
    margin: 17px 0 15px;
    border: none;
    background-color: var(--iseditor-hr-color); /* 171, 179, 255  #2b2b2c */
}

.fileUpload {
    padding: 5px 0 3px; 
}

.fileUploadSummary {
    margin: 0 20px 0 0; 
}

.attention {
    margin: 0 0 35px;
    color: var(--changelog-gray-color); /* #b8b8a2 */
    font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    line-height: 20px; 
}

.checkbox {
    margin: 0 10px 0;
}

.readOnly {
    display: inline-flex;
    padding-left: 140px;
}

.readOnly_input input {
    width: 70px;
    /*min-width: 5ch;*/
}

.fileError {
    margin: 10px 0 10px; /* 15px 0 0 */
}

.sendError {
    margin: 0 0 35px; 
}
