.base {
    width: 565px; 
}

.realms {
    display: flex;
    flex-flow: row wrap;
    align-content: stretch;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 -25px 0;
    padding: 13px 0 0; 
}

.realm {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    box-sizing: border-box;
    width: 267px;
    height: 50px;
    margin: 0 0 30px;
    padding: 0 40px;
    background-repeat: no-repeat;
    background-position: center;
    color: var(--attention-mark-color); /* #fefbed */
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer; 
}
.realm::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 25px;
    background-color: rgba(28, 28, 30, 0.1);
    content: '';
    transition: background-color 0.4s; 
}
.realm:hover::after {
    background-color: rgba(28, 28, 30, 0.6); 
}

.realm_lock {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    box-sizing: border-box;
    width: 267px;
    height: 50px;
    margin: 0 0 30px;
    padding: 0 40px;
    background-repeat: no-repeat;
    background-position: center;
    color: var(--attention-mark-color); /* #fefbed */
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    /*cursor: pointer; */
}
.realm_lock::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 25px;
    background-color: rgba(28, 28, 30, 0.6);
    content: '';
    transition: background-color 0.4s; 
}
/*.realm_lock:hover::after {
    background-color: rgba(28, 28, 30, 0.6); 
}*/

.realm__lesta {
    background-image: url(/public/static/portal/images/dialog-login-ru.png); 
}
.realm__wargaming {
    background-image: url(/public/static/portal/images/dialog-login-eu.png); 
}

.realm__ru {
    background-image: url(/public/static/portal/images/dialog-login-ru.png); 
}
.realm__eu {
    background-image: url(/public/static/portal/images/dialog-login-eu.png); /* dialog-login-eu.png */
}
.realm__us {
    background-image: url(/public/static/portal/images/dialog-login-eu.png); /* dialog-login-us.png */
}
.realm__sg {
    background-image: url(/public/static/portal/images/dialog-login-eu.png); /* dialog-login-sg.png */
}
