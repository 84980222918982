.base {
    display: flex;
    flex-flow: column nowrap;
    box-sizing: border-box;
    height: 100%; 
}

.head {
    flex: 0 0 auto; 
}

.body {
    flex: 1 1 100%; 
}

.inner {
    position: relative;
    height: 100%; 
}

.content {
    height: 100%; 
}
@media all and (max-width: 1365px) {
    .content {
        width: 100%; 
    }
}
@media all and (min-width: 1366px) and (max-width: 1407px) {
    .content {
        width: 929px; 
    } 
}
@media all and (min-width: 1408px) {
    .content {
        width: 929px; 
    }
}

.form {
    padding: 33px 0 0; 
}
.form.isDisabled {
    opacity: .3;
    pointer-events: none; 
}

.divider {
    margin: 30px 0; 
}

.attentions {
    margin: 37px 0 55px; 
}

.attention {
    margin: 20px 0;
    color: var(--changelog-gray-color); /* #b8b8a2 */
    font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 20px; 
}

.buttons {
    position: relative; 
}

.button {
    display: inline-block;
    vertical-align: top; 
}
.button:not(:last-child) {
    margin: 0 30px 0 0; 
}

.actionRemoveMod {
    position: absolute;
    top: 0;
    right: 0; 
}

.clampLines {
    word-wrap: break-word; 
}

.notificationModerator {
    margin: 30px 0; 
}

.notification:not(:last-child) {
    margin: 0 0 20px; 
}

.info {
    padding: 17px 0;
    color: var(--changelog-gray-color); /* #b8b8a2 */
    font-size: 16px;
    line-height: 36px; 
}

.notificationContent {
    position: relative;
    font-size: 14px;
    line-height: 20px; 
}
.notificationContent mark {
    background: transparent;
    color: var(--action-red-color);
    font-size: 20px;
    line-height: 20px; 
}
.notificationContent::before {
    position: relative;
    top: 2px;
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 6px;
    border-radius: 50%;
    background: url(/public/static/portal/images/edit-notification-error.png) center no-repeat;
    vertical-align: top;
    content: ''; 
}
.notificationContent__info::before {
    background-color: var(--create-mod-notification-content-color); 
}
.notificationContent__warning::before {
    background-color: var(--action-red-color); 
}
