.base {
    display: flex;
    flex-flow: column nowrap;
    box-sizing: border-box;
    height: 100%;
    padding: 0 0 30px; 
}

.head {
    position: relative;
    z-index: 2;
    flex: 0 0 auto; 
}

.body {
    position: relative;
    z-index: 1;
    flex: 1 1 100%;
    /* stylelint-disable order/properties-order */
    -ms-flex: 1 0 auto;
    /* stylelint-enable */ 
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 50px; 
}

.titleSeparator {
    margin: 0 2px 0 4px;
    opacity: .3; 
}

.muted {
    color: #434343; 
}

.filter {
    margin: 7px 0 0;
    line-height: 1; 
}
.filter.isDisabled {
    opacity: .3;
    pointer-events: none; 
}

.select {
    display: inline-block;
    width: 200px; 
}

.content {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start; 
}
@media all and (max-width: 1365px) {
    .content {
        margin: 0 -15px -50px; 
    }
}
@media all and (min-width: 1366px) and (max-width: 1407px) {
    .content {
        margin: 0 -13px -50px; 
    }
}
@media all and (min-width: 1408px) {
    .content {
        margin: 0 -20px -50px; 
    }
}

@media all and (max-width: 1365px) {
    .mod {
        margin: 0 15px 50px; 
    }
}

@media all and (min-width: 1366px) and (max-width: 1407px) {
    .mod {
        margin: 0 13px 50px; 
    }
}

@media all and (min-width: 1408px) {
    .mod {
        margin: 0 20px 50px; 
    }
}

.notification {
    margin: 0 0 60px; 
}
