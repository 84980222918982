.body {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-image: url(/public/static/portal/images/quit_bg.png); /* header-bg.jpg */
    background-size: contain;
    background-position-x: center;
    background-repeat: no-repeat;
}

.block {
    text-align: center; 
    text-shadow: 1px 1px 2px rgba(5, 5, 5, 0.2); /* Параметры тени */
    backdrop-filter: blur(1px);
    border: 1px solid transparent;
    border-radius: 100px;
}

.title {
    margin: 65px 0 15px;
    color: rgb(206,212,224,1); /* #fffee5 rgb(206,212,224,1) */
    font-size: 30px;
    font-weight: 700;
    line-height: 1; 
}

.content {
    margin: 0 0 34px;
    color: rgb(206,212,224,1); /* #fffee5 rgb(206,212,224,1) */
    font-size: 18px;
    line-height: 28px; 
}