.base {
    display: inline-block;
    vertical-align: top; 
}
.base.isDisabled {
    opacity: .3;
    pointer-events: none; 
}

.toggle {
    position: relative;
    display: inline-flex;
    flex-flow: row nowrap;
    height: 21px;
    border-radius: 10px;
    background-color: rgba(39, 39, 41, 0.75); /* #050505 */
    user-select: none; 
}

.item {
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    min-width: 118px;
    height: 100%;
    padding: 0 18px;
    border-radius: 10px;
    color: #717e85; /* #858271 */
    font-size: 11px;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.4s, color 0.4s; 
}
.item:hover:not(.isChecked) {
    color: #bed4e9; /* #e9e3be */
}
.item.isChecked {
    background-color: #bed4e9; /* #e9e3be */
    color: rgba(39, 39, 41, 1); /* #050505 */
    cursor: default; 
}
