.base {
  height: 50px;
  padding: 0 50px;
  border: none;
  border-radius: 25px;
  background-color: var(--main-blue-color); /*rgba(0, 81, 255, 0.75); /* #f9b723 */
  color: var(--white-color);
  font-family: "Roboto Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 48px;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
  transition: background-color 0.4s; 
}
.base:focus {
  outline: none; 
}
.base:hover:not(.isDisabled) {
  background-color: var(--action-disabled-blue-color); /* #0051ff; /* #ffd428 */ 
}
.base.isDisabled {
  opacity: .3;
  cursor: default; 
}
.base mark {
  background: transparent;
  color: var(--black-color); 
}

.content {
  position: relative;
  z-index: 2;
}

@keyframes blink-button {
  0% {
      -webkit-transform: translateX(-87px) skew(-32deg);
      transform: translateX(-87px) skew(-32deg)
  }

  50%,to {
      -webkit-transform: translateX(100%) skew(-32deg);
      transform: translateX(100%) skew(-32deg)
  }
}

.glitch {
  position: relative;
  overflow: hidden;
  opacity: 1;
}
.glitch:before {
  width: 100%;
  height: 100%;
  border-left: 48px solid #005fff; /* #ff8200 */
  left: 0;
}
.glitch:after {
  width: 115%;
  height: 115%;
  border-left: 14px solid #005fff; /* #ff8200 */
  left: 58px;
}

.glitch:after, .glitch:before {
  content: "";
  position: absolute;
  top: 0;
  will-change: transform;
  -webkit-animation: blink-button 3s infinite ease;
  animation: blink-button 3s infinite ease;
}
