.base {
    display: flex;
    flex-flow: column nowrap;
    box-sizing: border-box;
    height: 100%; 
}

.head {
    flex: 0 0 auto; 
}

.body {
    flex: 1 1 100%; 
}

.inner {
    position: relative;
    height: 100%; 
}

.content {
    height: 100%; 
}
@media all and (max-width: 1365px) {
    .content {
        width: 100%; 
    }
}
@media all and (min-width: 1366px) and (max-width: 1407px) {
    .content {
        width: 929px; 
    } 
}
@media all and (min-width: 1408px) {
    .content {
        width: 929px; 
    }
}

.form {
    padding: 33px 0 0; 
}
.form.isDisabled {
    opacity: .3;
    pointer-events: none; 
}

.divider {
    margin: 30px 0; 
}

.attentions {
    margin: 37px 0 55px; 
}

.attention {
    margin: 20px 0;
    color: #a2a7b8; /* #b8b8a2 */
    font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 20px; 
}

.buttons {
    position: relative; 
}

.button {
    display: inline-block;
    vertical-align: top; 
}
.button:not(:last-child) {
    margin: 0 30px 0 0; 
}

.actionRemoveMod {
    position: absolute;
    top: 0;
    right: 0; 
}

.clampLines {
    word-wrap: break-word; 
}

.notificationModerator {
    margin: 30px 0; 
}

.notification:not(:last-child) {
    margin: 0 0 20px; 
}

.info {
    padding: 17px 0;
    color: #a2a7b8; /* #b8b8a2 */
    font-size: 16px;
    line-height: 36px; 
}

.notificationContent {
    position: relative;
    font-size: 14px;
    line-height: 20px; 
}
.notificationContent mark {
    background: transparent;
    color: #db2d1b;
    font-size: 20px;
    line-height: 20px; 
}
.notificationContent::before {
    position: relative;
    top: 2px;
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 6px;
    border-radius: 50%;
    background: url(/public/static/portal/images/edit-notification-error.png) center no-repeat;
    vertical-align: top;
    content: ''; 
}
.notificationContent__info::before {
    background-color: #395eff; 
}
.notificationContent__warning::before {
    background-color: #db2d1b; 
}


/* owner name block */
.ownerName_base {
    display: flex;
    flex-flow: row nowrap; 
    margin: 30px 0;
}
.ownerName_label {
    flex: 0 0 42px; /* 342px */
    box-sizing: border-box;
    /*margin: 4px 0 0;
    padding: 0 40px 0 0; */
}
/* stylelint-disable */
_:-ms-lang(x), .ownerName_label {
    flex: 0 0 2px; /* 302px */
}
/* stylelint-enable */
.ownerName_field {
    line-height: 1;
    /*text-transform: uppercase;*/
    white-space: nowrap; 
    color: #0051ff; /* f9b723 */
    font-size: 20px;
    cursor: pointer;
}
.ownerName_field.ownerName_field_isBlock {
    display: inline-block;
    height: 32px;
    padding: 0 20px;
    border-radius: 10px;
    color: #050505;
    font-size: 11px;
    font-weight: 700;
    line-height: 32px;
    vertical-align: top; 
}
  

/* all downloads */
.downloads_base {
    display: flex;
    flex-flow: row nowrap; 
    margin: 30px 0;
}
.downloads_label {
    flex: 0 0 342px; /* 342px */
    box-sizing: border-box;
}
.downloads_field {
    line-height: 1;
    text-transform: uppercase;
    white-space: nowrap; 
    color: #bedbe9;
    font-size: 20px;
}


/* raiting mod */
.raiting_base {
    display: flex;
    flex-flow: row nowrap; 
    margin: 30px 0;
}
.raiting_label {
    flex: 0 0 342px; /* 342px */
    box-sizing: border-box;
}
.raiting_field {
    line-height: 1;
    white-space: nowrap; 
    color: #bedbe9;
    font-size: 20px;
}
.raiting_field p {
    margin: 5px 0;
}