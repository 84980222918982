.base {
    display: flex;
    flex-flow: row nowrap;
    box-sizing: border-box;
    height: 100%;
}
  
.head {
    margin: 0 0 32px;
}

.body {
    flex: 1 1 100%;
    -ms-flex: 1 0 auto;
}
.inner {
    padding: 0px 0 0; 
}
/*@media all and (min-width: 1408px) {
    .inner {
        width: 929px; 
    } 
}*/
@media all and (max-width: 1365px) {
    .inner {
        width: 1022px; /* 1122px */
        /*padding: 0 28px 50px; */
    } 
}
@media all and (min-width: 1366px) and (max-width: 1407px) {
    .inner {
        width: 1366px;
        /*padding: 30px 40px 50px;*/
    } 
}
@media all and (min-width: 1408px) {
    .inner {
        width: 1408px;
        /*padding: 30px 40px 50px;*/
    } 
}