.base {
    display: flex;
    flex-flow: row nowrap;
    align-items: center; 
}

.label {
    flex: 0 0 342px;
    margin: 4px 0 0; 
}

.field {
    display: flex;
    flex: 1 1 100%;
    flex-flow: row nowrap;
    white-space: nowrap; 
}

.action:not(:last-child) {
    margin: 0 120px 0 0; 
}


.fieldSelect {
    /*flex: 1 1 100%; 
    align-content: center; */
    /*display: flex;
    flex: 1 1 230px;
    align-items: center;
    box-sizing: border-box;
    overflow: hidden;
    color: rgba(206, 212, 224, 1);*/
    text-transform: uppercase;
}

.selectSelect {
    width: 230px; 
}