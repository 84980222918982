:root {
  --main-color: rgba(21, 26, 37, 0.95); /* rgba(39, 39, 41, 0.95) */
  --main-30c-color: rgba(21, 26, 37, 0.3);
  --main-50c-color: rgba(21, 26, 37, 0.5);
  --main-100c-color: rgba(21, 26, 37, 1);
  --main-blue-color: #0051ff;
  --all-text-color: rgba(206, 212, 224, 1); /* #e1eff9 */
  --all-text-40c-color: rgba(206, 212, 224, 0.4); 
  --all-text-20c-color: rgba(206, 212, 224, 0.2); 
  --white-color: #fff;
  --black-color: #050505;
  --red-color: #db2d1b;
  --lightgreen-color: #5eb940;
  --lightyellow-color: #f3f361;
  --black-black-color: #000;
  --black-black-85c-color: rgba(0, 0, 0, 0.85);
  --black-black-75c-color: rgba(0, 0, 0, 0.75);
  --black-black-40c-color: rgba(0, 0, 0, 0.4);
  --black-black-20c-color: rgba(0, 0, 0, 0.2);
  --black2-color: rgba(5, 5, 5, 0);
  --black2-50c-color: rgba(5, 5, 5, 0.5);
  --black2-20c-color: rgba(5, 5, 5, 0.2);
  --black3-color: #1c1c1e;

  /* footer */
  --footer-copy-color: #525252;

  /* nav */
  --link-hover-after-color: #b9d3e4;
  --link-hover-color: #bfd3fe;
  --link-active-color: rgba(0, 92, 251, 0.8);

  --btn-user-menu-base-color: #717985; /* #858271 */
  --btn-user-menu-after-color: rgba(21, 26, 37, 1); /* rgba(39, 39, 41, 1) */
  --granica-after-color: #272729; /* rgba(39, 39, 41, 1) rgba(255, 39, 41, 0.95) */
  --btn-user-menu-granica-before-color: #454545;
  --btn-user-menu-granica-after-color: #454545;
  --btn-user-menu-login-text-hover-color: #bfd3fe;

  /* usermenu*/
  --container-umenu-border-color: #454545;
  --notification-panel-border-color: #1d1d1d;
  --notification-link-hover-color: rgba(191, 211, 254, 1); /* #e5e5e5 */
  --notification-link-important-color: #3469d1; /* #d19834 */
  --notifications-empty-color: #7c7e80;
  --notifications-settings-color: #3a3a3a;
  --notifications-back-color: #b2b5b7; /* #737576 */
  --notifications-back2-color: rgba(178, 181, 183, 0.5);
  --notifications-settings-back-color: #737576;
  --notifications-settings-back-active-background-color: #338fff; /* 858585 */
  --notifications-settings-back-hover: #338fff; /* ffc233 */
  --notifications-settings-item-border-color: #3053eb; /* 272727 */
  --notifications-settings-item-border2-color:: rgb(86 89 101 / 75%); /* 39 39 39 */
  --notifications-settings-message-color: #707273;
  --notifications-settings-label-color: #babdbf;
  --notifications-settings-label-before-color: #2e82db; /* dba72e */
  --notifications-settings-label-before-border-color: #3d3d3d;
  --notification-button-color: #d7d7d7; /* 707273 */
  --notification-button-border-color: #333333; /* 707273 */

  /* notifyItem */
  --cm-notifications-item-background-color: #070812; /* 0e0e0e  191919 */
  --cm-notifications-item-background2-color: #070812; /* 171717 #1c1c1c  454545*/
  --cm-notifications-item-border-color: #1d1d1d; /* 1d1d1d */
  --cm-notifications-item-border2-color: #313335;
  --cm-notifications-item-border-hover-color: #373737; /* 1d1d1d 373737 454545 */
  --cm-notifications-item-border2-hover-color: rgba(207,207,207,0.02);
  --cm-notifications-item-background-hover-before-color: #222222; /* 151515 */
  --cm-notifications-item-background2-hover-before-color: rgba(207,207,207,0.02);
  --cm-notifications-item-unread-before-color: #151515;
  --cm-notifications-item-unread2-before-color: rgba(255,255,255,0.05);
  --cm-text-link-color: #707273;
  --cm-notifications-time-color: #7c7e80;
  --cm-notifications-close-color: #6f6f6f;
  --cm-notifications-close-hover-color: #a0a0a0;
  --cm-scroll-track-background-color: #1d1d1d;
  --cm-scroll-thumb-background-color: #2f2f2f;

  /* search */
  --search-base-color: #717985; /* 858271 */
  --search-background-input-color: rgba(5, 5, 5, 0.4);
  --search-input-focus-color: rgba(0, 183, 255, 0.2);
  --search-placeholder-color: #717985; /* 858271 */

  /* categories */
  --categories-background-color: linear-gradient(rgba(21, 26, 37, 0.55), rgba(21, 26, 37, 0.05));
  --categories-caption-color: #becee9;

  /* cardMod */
  --summary-mark-color: rgb(171, 179, 255);
  --ownername-color: #becee9;
  --ownername-clickable-color: #84bdf3;
  --ownername-hover-color: #55a3f7;
  --versions-red-color: #db2d1b; /* #db2d1b ff503e */
  --viewmodicon-color: #23aef9; /* 23c7f9 */
  --viewmodicon-hover-color: #28b0ff;

  /* actions */
  --action-blue-color: #2360f9; /* f9b723 2360f9   rgba(0, 118, 255, 1) */
  --action-disabled-blue-color: #28b0ff; /* ffd428   28adff */
  --action-red-color: #db2d1b; /* #db2d1b ff503e    rgb(219, 45, 27, 1) */
  --action-disabled-red-color: #ff482a;
  --btn-red-35c-color: rgba(219, 45, 27, 0.35);
  --btn-dark-blue-color: #0a0e80; /* 0a1080 */
  --btn-gray-20c-color: rgba(191, 211, 254, 0.2);
  --btn-cooment-color: rgba(63, 52, 127, 1);
  --btn-cooment-hover-color: rgba(63, 52, 127, 0.65);
  --btn-grays-20c-color: rgba(185, 202, 228, 0.2);
  --btn-grays-color: #b9c3e4;
  --btn-cancel-color: #717785;
  --btn-cancel-after-color: rgba(185, 206, 228, 0.3);

  /* comments widget */
  --widget-background-color: rgba(45, 60, 69, 0.5);
  --widget-comm-background-color: rgba(63, 52, 127, 1);

  --iseditor-li-before-background-color: #757569;
  --iseditor-h4-color: #edf7fe;
  --iseditor-hr-color: rgba(84, 84, 84, 1); /* #d2d2ee   rgba(39, 39, 41, 0.75) */

  /* create mod */
  /*--create-mod-info-color: #a2abb8; */
  --create-mod-notification-content-color: #395eff;

  --changelog-gray-color: #a2abb8; /* a2acb8 */
  --changelog-background-blue-4c-color: rgba(225, 228, 249, 0.04);
  --changelog-background-draggable-color: #27284d; /* #333232 */
  --dialog-error-gray-color: #a2a9b8;
  --attention-mark-color: #edf3fe;

  --edit-comments-color: #2b2b2c;
  --edit-comments-item-inner-color: rgba(249, 245, 225, 0.04);
  --edit-comments-item-approved-color: #5eb940;
  --edit-comments-item-summary-color: #5f6b72;

  /* audio player */
  --audioplayer-title-color: #b6c5e6;
  --audioplayer-caption-color: #717d85;
  --audioplayer-isuploading-border-color: #29282a;
  --audioplayer-isuploading-background-color: #131314;

  /* infoblock adminpanel */
  --infoblock-section-color: rgba(60, 70, 84, 0.45); /* 46, 60, 79, 0.45 */
  --infoblock-secondary-color: #787aaa;

  /* eula */
  --eula-editor-ul-color: #697375;
  --eula-editor-h3-color: rgba(233, 253, 255, 1);
  --eula-editor-h4-color: #edf7fe;
  --eula-editor-mark-color: #1b83fa;

  /* fileUpload */
  --fileupload-title-hover-color: #2869ff;
  --fileupload-caption-color: #717d85;
  --fileupload-caption-mark-color: #e1ecf9;
  --fileupload-caption-em-color: #db2d1b;
  --fileupload-isuploading-1-color: #29282a;
  --fileupload-isuploading-2-color: #131314;

  /* filterSelect */
  --filterSelect-value-background-color: rgba(191, 211, 254, 0.9);
  --filterSelect-value-color: #050505;
  --filterSelect-value-isopened-color: #e1ecf9;
  --filterSelect-dropdown-color: #e1ecf9;
  --filterSelect-option-color: #050505;
  --filterSelect-option-hover-color: rgba(18, 18, 18, 0.2);

  /* followed */
  --title-ismuted-mark-color: #434343;
}


/*body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}*/
