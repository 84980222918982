@media all and (max-width: 1365px) {
    .base {
        margin: 60px 0 0;
        padding: 34px 0 0;
        border-top: 1px solid var(--edit-comments-color); 
    }
}
  
@media all and (min-width: 1366px) and (max-width: 1407px) {
    .base {
        position: absolute;
        top: 0;
        right: 0;
        width: 302px; 
    }
}
  
@media all and (min-width: 1408px) {
    .base {
        position: absolute;
        top: 0;
        right: 0;
        width: 302px; 
    }
}
  
.base.isDisabled {
    opacity: .3;
    pointer-events: none; 
}
  
.title {
    margin: 0 0 28px; 
}
  
@media all and (max-width: 1365px) {
    .comments {
        margin: 35px 0 0; 
    }
}
  
@media all and (min-width: 1366px) and (max-width: 1407px) {
    .comments {
        margin: 55px 0 0; 
    }
}
  
@media all and (min-width: 1408px) {
    .comments {
        margin: 55px 0 0; 
    }
}
  
.button--2haYe {
    margin: 30px 0 0;
    text-align: center; 
}
  
.paginator--3TRT0 {
    margin: 30px 0 0; 
}
  
.paginatorInner--2Njy9 {
    position: relative;
    left: 50%;
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
    transform: translateX(-50%); 
}
  