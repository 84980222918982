.base {
    display: flex;
    flex-flow: row nowrap; 
}

.label {
    flex: 0 0 342px;
    margin: 4px 0 0; 
}

.field {
    flex: 1 1 100%; 
}

.fileUploadSummary {
    margin: 0 20px 0 0; 
}

.voiceovers {
    margin: 0 -29px 0 0; 
}

.item {
    display: inline-block;
    width: 240px; /* 176px 550px */
    height: 50px; /* 99px 90px */
    margin: 0 29px 30px 0;
    vertical-align: top; 
}

.voiceover {
    position: relative;
    z-index: 2;
    display: inline-block;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    vertical-align: top;
    transition: box-shadow 0.4s; 
}
.voiceover.isDraggable {
    cursor: move; 
}
.voiceover.isDraggable::before, .voiceover.isDraggable::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 10px;
    opacity: 0;
    content: '';
    transition: opacity 0.4s;
    pointer-events: none; 
}
.voiceover.isDraggable::before {
    z-index: 1;
    background-color: var(--black-black-40c-color); 
}
.voiceover.isDraggable::after {
    z-index: 2;
    background: url(/public/static/portal/images/edit-screenshots-hover.png) center no-repeat; 
}
.voiceover.isDraggable:hover::before, .voiceover.isDraggable:hover::after {
    opacity: 1; 
}
.dragged .voiceover.isDraggable {
    box-shadow: 0 0 0 1px var(--main-blue-color), 0px 0px 40px 0px var(--btn-cooment-hover-color); /* f9b723   rgba(255, 84, 0, 0.3) */
}
.dragged .voiceover.isDraggable::after {
    opacity: 1; 
}

.audio {
    display: inline-block;
    width: 240px; /* 176px 550px */
    height: 50px; /* 99px 90px */
    border-radius: 10px;
    vertical-align: top; 
}

.previewRemove {
    position: absolute;
    top: -10px;
    right: -10px;
    z-index: 3; 
}

.dragged {
    z-index: 993; 
}
