.base {
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 21px;
    border-radius: 10px;
    background-color: rgba(39, 39, 41, 0.95); /* 050505  rgba(39, 39, 41, 0.95) */
    vertical-align: top;
    user-select: none; 
}

.item {
    width: 15px;
    height: 15px;
    margin: 0 3px;
    border-radius: 50%;
    background-image: url(/public/static/portal/images/flags.png);
    background-repeat: no-repeat;
    cursor: pointer;
    transition: box-shadow 0.4s; 
}
.item:hover:not(.isChecked) {
    box-shadow: inset 0 0 0 1px #bed7e9; /* e9e3be */
}
.item__ru {
    background-position: 0 0; 
}
.item__en {
    background-position: 0 -15px; 
}
.item.isChecked {
    box-shadow: inset 0 0 0 1px rgba(39, 39, 41, 0.3), 0 0 0 3px #bed7e9; /* e9e3be */
    cursor: default; 
}
