.row {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start; 
}

.label {
    flex: 0 0 342px; 
}

.field {
    flex: 1 1 100%; 
}

.tr {
    padding: 15px 0; 
}
.tr:first-child {
    padding-top: 0; 
}
.tr:last-child {
    padding-bottom: 0; 
}
.tr:not(:last-child) {
    border-bottom: 1px solid var(--main-color); /* 2b2b2c */
}

.inner {
    display: flex;
    flex-flow: row nowrap;
    align-items: baseline; 
}

.td {
    box-sizing: border-box;
    line-height: 16px; 
}
.td__mod-version {
    flex: 0 0 190px; 
}
.td__game-version {
    flex: 0 0 80px; /* 140px */
    padding-right: 10px;
    color: var(--link-hover-after-color); /* bedbe9  e9e3be */
    font-size: 14px; 
}
.td__uploaded-at {
    flex: 0 0 120px;
    padding-right: 10px;
    color: var(--link-hover-after-color); /* e9e3be */
    font-size: 14px; 
}
.td__state {
    flex: 1 1 100%;
    padding-right: 10px;
    color: var(--link-hover-after-color); /* e9e3be */
    font-size: 11px;
    text-transform: uppercase;
    white-space: nowrap; 
}
.td__remove {
    flex: 0 0 21px; 
}

.notification {
    margin: 15px 0 0; 
}
