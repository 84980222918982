.cm_scroll {
    position: relative;
    overflow: hidden;
    display: block; /* none */
}
.cm_scroll__notifications {
    margin-top: 54px;
}

.cm_scroll_container {
    -webkit-tap-highlight-color: transparent;
    position: relative;
    width: 100%;
    z-index: 1;
}


.cm_scroll_track {
    -webkit-transition: 0.2s opacity ease;
    transition: 0.2s opacity ease;
    background-color: var(--cm-scroll-track-background-color);
    bottom: 0;
    display: none;
    position: absolute;
    opacity: 0;
    right: 2px;
    top: 0;
    width: 6px;
    z-index: 2;
}
@media all and (max-width: 1023px) {
    .cm_scroll_track {
        display:none;
    }
}
.cm_scroll:hover .cm_scroll_track {
    opacity: 1;
}
.cm_scroll_thumb {
    background-color: var(--cm-scroll-thumb-background-color);
    position: absolute;
    width: 6px !important;
}