.base {
    display: flex;
    flex-flow: column nowrap;
    box-sizing: border-box;
    height: 100%;
    padding: 0 0 70px; 
}

.head {
    flex: 0 0 auto; 
}

.hidden {
    display: none; 
}

.title.isMuted mark {
    color: var(--title-ismuted-mark-color); 
}

.body {
    flex: 1 1 100%;
    /* stylelint-disable order/properties-order */
    -ms-flex: 1 0 auto;
    /* stylelint-enable */
    margin: 20px 0 -20px; 
}

.divider {
    margin: 20px 0; 
}

.itemsHeader {
    position: relative;
    display: flex;
    align-items: center;
    height: 50px;
    margin: 0 0 20px; 
}

.ItemsButton {
    position: absolute;
    top: 0;
    right: 0;
    width: 350px; 
}
