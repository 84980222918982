.base {
    margin: 40px 0; 
}

.caption {
    position: relative;
    margin: 0 0 25px; 
}

.cancel {
    position: absolute;
    top: 1px;
    right: 0; 
}

.row {
    display: flex;
    flex-flow: row nowrap; 
}
.row:not(:last-child) {
    margin: 0 0 20px; 
}

.label {
    flex: 0 0 342px;
    box-sizing: border-box;
    margin: 4px 0 0;
    padding: 0 40px 0 0; 
}

/* stylelint-disable */
_:-ms-lang(x), .label {
    flex: 0 0 302px; 
}

/* stylelint-enable */
.field {
    flex: 1 1 100%;
    overflow: hidden; 
}
.field__editor {
    overflow: visible; 
}

.divider {
    margin: 40px 0; 
}

.lang {
    position: relative;
    display: inline-block;
    padding: 0 0 0 28px;
    color: var(--attention-mark-color); /* e1f4f9   f9f5e1 */
    font-size: 18px; 
}
.lang::before {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 1px var(--main-30c-color); 
}
.lang__ru::before {
    background: url(/public/static/portal/images/flags.png) 0 0 no-repeat;
    content: ''; 
}
.lang__en::before {
    background: url(/public/static/portal/images/flags.png) 0 -15px no-repeat;
    content: ''; 
}
