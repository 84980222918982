.base {
    position: relative;
    display: inline-block;
    cursor: pointer; 
}
.base.isDisabled {
    opacity: .3;
    pointer-events: none; 
}

.input {
    position: absolute;
    visibility: hidden;
    opacity: 0; 
}

.check {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    margin: 0 10px 0 0;
    border: 1px solid var(--action-blue-color); /* f9b723   #2399f9 */
    border-radius: 3px;
    vertical-align: middle;
    transition: background-color 0.4s; 
}
.base:hover .check {
    background-color: var(--btn-dark-blue-color); /* rgba(255, 212, 40, 0.2)   rgba(40, 166, 255, 0.2) */
}
.base.isChecked .check {
    background-color: var(--action-blue-color); /* f9b723   #2399f9 */
}
.base.isChecked:hover .check {
    background-color: var(--action-disabled-blue-color); /* ffd428  #2897ff */
}
.check::before {
    position: absolute;
    top: -1px;
    left: -1px;
    width: 16px;
    height: 16px;
    background: url(/public/static/portal/images/checkbox-check.png) center no-repeat;
    opacity: 0;
    content: '';
    transition: opacity 0.4s; 
}
.base.isChecked .check::before {
    opacity: 1; 
}

.caption {
    color: var(--action-blue-color); /* f9b723   #2399f9 */
    font-size: 11px;
    line-height: 1;
    text-transform: uppercase;
    transition: color 0.4s; }
.base:hover .caption {
    color: var(--action-disabled-blue-color); /* ffd428   #2897ff */
}
