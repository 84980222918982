.base {
    background-color: var(--widget-background-color);
    border-radius: 15px;
}

.item {
    padding-top: 10px;
    margin-left: 3.0em;
    margin-right: 2.0em;
}
/*.item:not(:last-child) {
    border-bottom: 1px solid rgba(210, 210, 238, 0.45);
}*/
.item:last-child {
    padding-bottom: 10px;
}

.item__btns {
    opacity: 0;
    float: right;
    position: relative;
    bottom: 25px;
}
.item__btn:not(:last-child) {
    margin-right: 10px;
}
.item:hover .item__btns {
    opacity: 1;
}


.item__createdAt {
    position: relative;
    left: 10px;
    top: -1px;
    font-size: 11px;
    opacity: 0.75;
}

.content {
    word-wrap: break-word; 
}
