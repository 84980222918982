.row {
    display: flex;
    flex-flow: row nowrap; 
}
.row:not(:last-child) {
    margin: 0 0 20px; 
}
  
.label {
    flex: 0 0 342px;
    margin: 4px 0 0; 
}
  
.field {
    flex: 1 1 100%; 
}
  
.divider {
    margin: 20px 0 20px 342px; 
}
  
.cancel {
    margin: -5px 0 15px;
    text-align: right; 
}
  