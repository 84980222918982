.base {
    display: flex;
    flex-flow: row nowrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    text-align: center; 
}

.message {
    margin: 0 0 25px;
    color: var(--all-text-color); /*color: #e9e3be;*/
    font-size: 20px;
    line-height: 28px; 
}

.icon {
    display: inline-block;
    width: 286px;
    height: 288px;
    background-image: url(/public/static/portal/images/error-empty-subscriptions-ru.png);
    background-repeat: no-repeat;
    background-position: center;
    vertical-align: top; 
}
.base__ru .icon {
    background-image: url(/public/static/portal/images/error-empty-subscriptions-ru.png); 
}
.base__en .icon {
    background-image: url(/public/static/portal/images/error-empty-subscriptions-en.png); 
}
