.base {
    position: relative;
    display: inline-block;
    width: 21px;
    height: 21px;
    color: var(--btn-cancel-color); /* 858271 */
    font-size: 11px;
    line-height: 21px;
    text-transform: uppercase;
    vertical-align: top;
    cursor: pointer;
    transition: color 0.4s; 
}
.base::before, .base::after {
    position: absolute;
    top: 0;
    width: 19px;
    height: 19px;
    border: 1px solid var(--btn-cancel-after-color); /* rgba(228, 222, 185, 0.3) */
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    content: '';
    transition: opacity 0.4s; 
}
.base::before {
    background-image: url(/public/static/portal/images/cancel.svg);
    opacity: 1; 
}
.base::after {
    border-color: var(--categories-caption-color); /* e9e3be */
    background-color: var(--categories-caption-color); /* e9e3be */
    background-image: url(/public/static/portal/images/cancel-hover.svg);
    opacity: 0; 
}
.base:hover {
    color: var(--categories-caption-color); /* e9e3be */
}
.base:hover::before {
    opacity: 0; 
}
.base:hover::after {
    opacity: 1; 
}
.base__left, .base__right {
    width: auto; 
}
.base__left {
    padding: 0 26px 0 0; 
}
.base__left::before, .base__left::after {
    right: 0; 
}
.base__right {
    padding: 0 0 0 26px; 
}
.base__right::before, .base__right::after {
    left: 0; 
}
