.Gallery_base {
    height: 100%; 
}
  
.Gallery_section {
    position: relative; 
}
.Gallery_section:not(:last-child) {
    margin-bottom: 50px; 
}
.Gallery_section__filtered {
    display: flex;
    flex-flow: column nowrap;
    box-sizing: border-box;
    height: 100%; 
}

.Gallery_filteredHead {
    position: relative;
    z-index: 2;
    flex: 0 0 auto; 
}

.Gallery_filteredBody {
    position: relative;
    z-index: 1;
    flex: 1 1 100%;
    /* stylelint-disable order/properties-order */
    -ms-flex: 1 0 auto;
    /* stylelint-enable */ 
}

.Gallery_title {
    display: inline-block;
    margin: 0 0 38px;
    vertical-align: top; 
}

.Gallery_reset {
    position: absolute;
    top: -1px;
    left: 100%;
    margin: 0 0 0 7px; 
}

.Gallery_filter {
    position: absolute;
    top: -2px;
    right: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start; 
}

.Gallery_toggle {
    margin: 0 30px 0 0; 
}

.Gallery_section__filtered .Gallery_inner {
    height: 100%; 
}

@media all and (max-width: 1365px) {
    .Gallery_inner {
        margin-right: -15px;
        margin-left: -15px; 
    } 
}

@media all and (min-width: 1366px) and (max-width: 1407px) {
    .Gallery_inner {
        margin-right: -13px;
        margin-left: -13px; 
    } 
}

@media all and (min-width: 1408px) {
    .Gallery_inner {
        margin-right: -20px;
        margin-left: -20px; 
    }
}

.Gallery_mods {
    margin: 0 0 -50px; 
}

.Gallery_mode {
    display: inline-block;
    vertical-align: top; 
}

.Gallery_mod {
    display: inline-block;
    vertical-align: top; 
}
@media all and (max-width: 1365px) {
    .Gallery_mod {
        margin: 0 15px 50px; 
    }
}
@media all and (min-width: 1366px) and (max-width: 1407px) {
    .Gallery_mod {
        margin: 0 13px 50px; 
    }
}
@media all and (min-width: 1408px) {
    .Gallery_mod {
        margin: 0 20px 50px; 
    }
}

.Gallery_button {
    margin: 40px 0 0;
    text-align: center; 
}
  

.FilterGameVersion_base {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    border-radius: 25px; 
}
.FilterGameVersion_base.FilterGameVersion_isDisabled {
    opacity: .3;
    pointer-events: none; 
}
    
.FilterGameVersion_select {
    min-width: 130px; 
}
    


/* mods section */
.mods {
    display: grid;
    /* Автоматически заполняем на всю ширину grid-контейнера */
    grid-template-columns: repeat(auto-fill, 302px); /* 274px */
    width: 100%;
    max-width: 1408px; /* Ширина grid-контейнера   1000px */
    justify-content: center;
    justify-items: center; /* Размещаем карточку по центру */
    column-gap: 30px; /* Отступ между колонками */
    row-gap: 40px; /* Отступ между рядами */
    margin: 0 auto;
}
@media all and (max-width: 1365px) {
    .mods {
        width: 1122px;
        padding: 0 28px;
    } 
}
@media all and (min-width: 1366px) and (max-width: 1407px) {
    .mods {
        width: 1366px;
        padding: 0 40px;
    } 
}
@media all and (min-width: 1408px) {
    .mods {
        width: 1408px;
        padding: 0 40px;
    } 
}