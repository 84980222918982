.base {
  position: relative;
  width: 302px;
  height: 340px; 
}

.base a {
  text-decoration: none;
  color: var(--white-color);
  white-space: unset;
}
.base a:hover {
  text-decoration: none;
  color: var(--white-color);
  white-space: unset;
}
  
.inner {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: var(--main-100c-color); /* #050505  rgba 191, 211, 254, 0.2 */
  box-shadow: 1px 2px 4px var(--black-black-20c-color);
  white-space: normal;
  cursor: pointer; 
}
.base.isDisabled .inner {
  opacity: .3;
  pointer-events: none; 
}
.rating {
  position: absolute;
  top: -15px;
  right: -15px;
  z-index: 2; 
}
.status {
  position: absolute;
  top: -16px;
  left: -8px;
  z-index: 2; }

.header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 170px;
  transition: opacity .2s; 
}
.header::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 60px;
  background: linear-gradient(to top, var(--main-100c-color) 0%, var(--black2-color) 100%);
  content: ''; 
}
.base:not(.isDisabled):hover .header {
  opacity: 0.4; 
}
  
  .body {
  position: relative;
  z-index: 2;
  flex: 1 1 auto;
  box-sizing: border-box;
  padding: 170px 20px 0;
  overflow: hidden;
  transition: padding .2s; }
.base:not(.isDisabled):hover .body:not(.isDownloadDisabled) {
  padding-top: 115px; }
  
.cover {
  display: block;
  width: 302px;
  height: 170px;
  border-radius: 10px 10px 0 0;
  background: url(/public/static/portal/images/cover.jpg) center no-repeat; 
}

.title {
  margin: 0 0 12px;
  color: var(--all-text-color); /*color: #e9e3be;*/
  font-size: 20px;
  font-weight: 700;
  line-height: 24px; 
}

.clampLines {
  word-wrap: break-word; 
}

.summary {
  display: flex;
  flex-flow: row wrap;
  margin: 0 0 16px;
  color: var(--all-text-color); /*color: #97947f;*/
  font-size: 13px;
  line-height: 16px; 
}
.summary mark {
  background: none;
  color: var(--summary-mark-color); /*color: #e9e3be; */
}
  
.owner {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; 
}

.ownerName {
  color: var(--ownername-color); /* #e9e3be  rgb(171, 179, 255) */
}
.ownerName.isClickable {
  color: var(--ownername-clickable-color); /* #fab81b */
  transition: color 0.4s; 
}
.ownerName.isClickable:hover {
  color: var(--ownername-hover-color); /* #ffd428 */
}

.versions {
  margin: 0 5px 0 0; 
}
.versions i {
  color: var(--versions-red-color);
  font-weight: 700;
  font-style: normal; 
}

.button {
  opacity: 0;
  transition: opacity .2s; 
}
.base:not(.isDisabled):hover .button {
  opacity: 1;
  transition: opacity .2s; 
}

.footer {
  position: relative;
  z-index: 3;
  flex: 0 0 auto;
  padding: 0 20px 16px; 
}

.tag {
  display: inline-block;
  margin: 4px 0;
  vertical-align: top; 
}
.tag:not(:last-child) {
  margin-right: 8px; 
}

.viewMod {
  position: absolute;
  top: 105px;
  left: 20px;
  z-index: 3; 
}

.viewModIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background: var(--viewmodicon-color) url(/public/static/portal/images/mod-view-icon.svg) no-repeat center;
  cursor: pointer;
  transition: background-color 0.4s; 
}
.viewModIcon:hover {
  background-color: var(--viewmodicon-hover-color); 
}

.viewModLabel {
  position: absolute;
  top: 17px;
  left: 50px;
  padding: 0 0 0 10px;
  color: var(--viewmodicon-color);
  font-size: 11px;
  line-height: 15px;
  text-transform: uppercase;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.4s; 
}
.viewModIcon:hover + .viewModLabel {
  opacity: 1; 
}

.hidden {
  display: none; 
}

.line-clamp-2 {
  display: -webkit-box;
  /*-webkit-line-clamp: 2;*/
  -webkit-box-orient: vertical;
  overflow: hidden;
}
